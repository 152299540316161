import { useState, useEffect } from "react";
var lightThemeColors = {
    red: "#FB475D",
    green: "#00B985",
    blue: "#007AFF",
    orange: "#FF9500",
    black: "#333333",
    gray: "#6B748B",
    background: "#FFFFFF",
};
var darkThemeColors = {
    red: "#FB475D",
    green: "#00B985",
    blue: "#007AFF",
    orange: "#FF9500",
    black: "#FFFFFF",
    gray: "#ADB4C6",
    background: "#101116",
};
var useChartColors = function () {
    var _a = useState(false), isDarkMode = _a[0], setIsDarkMode = _a[1];
    useEffect(function () {
        try {
            var mediaQuery_1 = window.matchMedia("(prefers-color-scheme: dark)");
            var handleChange_1 = function (e) { return setIsDarkMode(e.matches); };
            // 初始设置
            setIsDarkMode(mediaQuery_1.matches);
            // 监听变化
            mediaQuery_1.addEventListener("change", handleChange_1);
            // 清理
            return function () { return mediaQuery_1.removeEventListener("change", handleChange_1); };
        }
        catch (error) {
            setIsDarkMode(false);
        }
    }, []);
    return isDarkMode ? darkThemeColors : lightThemeColors;
};
export default useChartColors;
