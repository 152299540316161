var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useEffect, forwardRef } from "react";
var AnimatedDiv = forwardRef(function (_a, ref) {
    var _b, _c, _d, _e;
    var children = _a.children, animate = _a.animate, style = _a.style, _f = _a.mass, mass = _f === void 0 ? 1.8 : _f, _g = _a.tension, tension = _g === void 0 ? 0.1 : _g, _h = _a.damping, damping = _h === void 0 ? 0.6 : _h, rest = __rest(_a, ["children", "animate", "style", "mass", "tension", "damping"]);
    var divRef = useRef(null);
    // Combine ref and divRef to handle refs correctly
    var setRefs = function (node) {
        divRef.current = node;
        if (typeof ref === "function") {
            ref(node);
        }
        else if (ref) {
            ref.current = node;
        }
    };
    // Initial states for animation
    var current = useRef({
        x: (_b = animate === null || animate === void 0 ? void 0 : animate.translateX) !== null && _b !== void 0 ? _b : 0,
        y: (_c = animate === null || animate === void 0 ? void 0 : animate.translateY) !== null && _c !== void 0 ? _c : 0,
        scale: (_d = animate === null || animate === void 0 ? void 0 : animate.scale) !== null && _d !== void 0 ? _d : 1,
        rotate: (_e = animate === null || animate === void 0 ? void 0 : animate.rotate) !== null && _e !== void 0 ? _e : 0,
    });
    // Velocities for animation
    var velocity = useRef({
        x: 0,
        y: 0,
        scale: 0,
        rotate: 0,
    });
    useEffect(function () {
        var _a, _b, _c, _d;
        if (animate && divRef.current) {
            var target_1 = {
                x: (_a = animate.translateX) !== null && _a !== void 0 ? _a : current.current.x,
                y: (_b = animate.translateY) !== null && _b !== void 0 ? _b : current.current.y,
                scale: (_c = animate.scale) !== null && _c !== void 0 ? _c : current.current.scale,
                rotate: (_d = animate.rotate) !== null && _d !== void 0 ? _d : current.current.rotate,
            };
            var animationFrame_1 = null;
            var step_1 = function () {
                // Forces applied
                var force = {
                    x: tension * (target_1.x - current.current.x),
                    y: tension * (target_1.y - current.current.y),
                    scale: tension * (target_1.scale - current.current.scale),
                    rotate: tension * (target_1.rotate - current.current.rotate),
                };
                // Acceleration
                var acceleration = {
                    x: force.x / mass,
                    y: force.y / mass,
                    scale: force.scale / mass,
                    rotate: force.rotate / mass,
                };
                // Update velocities with damping
                velocity.current.x = velocity.current.x * damping + acceleration.x;
                velocity.current.y = velocity.current.y * damping + acceleration.y;
                velocity.current.scale =
                    velocity.current.scale * damping + acceleration.scale;
                velocity.current.rotate =
                    velocity.current.rotate * damping + acceleration.rotate;
                // Update current positions
                current.current.x += velocity.current.x;
                current.current.y += velocity.current.y;
                current.current.scale += velocity.current.scale;
                current.current.rotate += velocity.current.rotate;
                // Apply transformations to the entire AnimatedDiv (outer div)
                if (divRef.current) {
                    divRef.current.style.transform = "\n              translate(" + current.current.x + "px, " + current.current.y + "px) \n              scale(" + current.current.scale + ") \n              rotate(" + current.current.rotate + "deg)\n            ";
                }
                // Continue the animation until close to target
                if (Math.abs(target_1.x - current.current.x) > 0.01 ||
                    Math.abs(target_1.y - current.current.y) > 0.01 ||
                    Math.abs(target_1.scale - current.current.scale) > 0.01 ||
                    Math.abs(target_1.rotate - current.current.rotate) > 0.01) {
                    animationFrame_1 = requestAnimationFrame(step_1);
                }
                else {
                    // Snap to final target values
                    if (divRef.current) {
                        divRef.current.style.transform = "\n                translate(" + target_1.x + "px, " + target_1.y + "px) \n                scale(" + target_1.scale + ") \n                rotate(" + target_1.rotate + "deg)\n              ";
                    }
                    cancelAnimationFrame(animationFrame_1);
                }
            };
            // Start the animation
            step_1();
            // Cleanup animation frame on unmount
            return function () {
                if (animationFrame_1) {
                    cancelAnimationFrame(animationFrame_1);
                }
            };
        }
        else if (divRef.current) {
            divRef.current.style.transform = "";
        }
    }, [animate]);
    return (React.createElement("div", __assign({ ref: setRefs, style: style }, rest), children));
});
export default AnimatedDiv;
