// @ts-ignore
import React, { Fragment } from "react";
// @ts-ignore
import clsx from "clsx";
export var Img = function (_a) {
    var light = _a.light, dark = _a.dark, className = _a.className, onClick = _a.onClick, style = _a.style;
    return (React.createElement(Fragment, null,
        React.createElement("img", { src: light, alt: "close", className: clsx("block dark:hidden", className), onClick: onClick, style: style }),
        React.createElement("img", { src: dark, alt: "close", className: clsx("hidden dark:block", className), onClick: onClick, style: style })));
};
