import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import clsx from "clsx";
import closeImg from "./icon-close.png";
import closeImgDark from "./icon-close-dark.png";
import { Img } from "../basics/img";
import { createPortal } from "react-dom";
import AnimatedDiv from "../basics/animatedDiv";
import { iosAndroid } from "../../lib/appOc";
import { classNames } from "../../lib/utils";
export var BottomSheet = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, children = _a.children, _b = _a.modalClose, modalClose = _b === void 0 ? true : _b, _c = _a.modalId, modalId = _c === void 0 ? "modal-portal" : _c, title = _a.title, _d = _a.removePx, removePx = _d === void 0 ? false : _d, closeButton = _a.closeButton, _e = _a.hideIndicator, hideIndicator = _e === void 0 ? false : _e;
    var _f = useState(true), render = _f[0], setRender = _f[1];
    var _g = useState(false), animate = _g[0], setAnimate = _g[1];
    var _h = useState(false), open = _h[0], setOpen = _h[1];
    var containerRef = useRef(null);
    var _j = useState(0), y = _j[0], setY = _j[1];
    var modalRoot = useRef(null);
    var timeoutId = useRef(null);
    useEffect(function () {
        var element = containerRef.current;
        var handleTransitionEnd = function () {
            if (timeoutId.current)
                clearTimeout(timeoutId.current);
            setAnimate(false);
            if (!isOpen && modalRoot.current) {
                document.body.removeChild(modalRoot.current);
                modalRoot.current = null;
            }
        };
        if (element)
            element.addEventListener("transitionend", handleTransitionEnd);
        // 清理事件监听器
        return function () {
            if (element)
                element.removeEventListener("transitionend", handleTransitionEnd);
        };
    }, [containerRef.current, isOpen]);
    useEffect(function () {
        if (isOpen) {
            iosAndroid("hidden", { hidden: 0 });
            // 创建一个新的 DOM 元素并添加到 body 中
            modalRoot.current = document.createElement("div");
            modalRoot.current.setAttribute("id", modalId);
            document.body.appendChild(modalRoot.current);
            // 挂载弹窗
            setRender(!render);
            // 等待挂载后，开始弹出动画
            if (timeoutId.current)
                clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(function () {
                setAnimate(true);
                setOpen(true);
            }, 100);
        }
        else {
            iosAndroid("hidden", { hidden: 1 });
            if (modalRoot.current) {
                if (timeoutId.current)
                    clearTimeout(timeoutId.current);
                setOpen(false);
                setAnimate(true);
                setY(0);
            }
        }
    }, [isOpen]);
    useEffect(function () {
        // 清理函数
        return function () {
            if (modalRoot.current)
                document.body.removeChild(modalRoot.current);
            if (timeoutId.current)
                clearTimeout(timeoutId.current);
        };
    }, []);
    var touchHandler = function (e) {
        var element = e.currentTarget;
        var touch = e.touches[0];
        var startY = touch.clientY;
        var origY = y;
        var lastDelta = 0;
        var speed = 0;
        var handleTouchMove = function (moveEvent) {
            // 单指拖动
            var moveTouch = moveEvent.touches[0];
            var moveY = moveTouch.clientY;
            var deltaY = startY - moveY;
            speed = (deltaY - lastDelta + speed) / 2;
            lastDelta = deltaY;
            setY(Math.max(0, origY - deltaY));
        };
        var handleTouchEnd = function () {
            if (speed > 0 || lastDelta > -80) {
                setY(0);
            }
            else {
                setIsOpen(false);
            }
            element.removeEventListener("touchmove", handleTouchMove);
            element.removeEventListener("touchend", handleTouchEnd);
        };
        element.addEventListener("touchmove", handleTouchMove, { passive: true });
        element.addEventListener("touchend", handleTouchEnd, { passive: true });
    };
    if (!modalRoot.current)
        return null;
    return createPortal(React.createElement("div", { className: "fixed inset-0 z-9999 w-full" },
        React.createElement("div", { className: clsx("absolute inset-0 bg-mask_black duration-300 ease-in-out w-full", open ? "opacity-100" : "opacity-0"), onClick: function () { return (modalClose ? setIsOpen(false) : null); } }),
        React.createElement(AnimatedDiv, { ref: containerRef, animate: !open ? undefined : { translateY: y }, className: clsx("absolute bottom-0 box-border w-full overflow-hidden rounded-t-r40 bg-modal_white", !open && "translate-y-full", animate && "transition duration-300 ease-in-out", !removePx && "px-36") },
            !hideIndicator && (React.createElement("div", { className: "relative flex justify-center px-12 pb-32 pt-12", onTouchStart: touchHandler },
                React.createElement("div", { className: "relative h-12 w-84 rounded-full bg-modal_indicator_gray" }))),
            title && (React.createElement("div", { className: "relative mb-48 flex items-center justify-between" },
                React.createElement("div", { className: "flex items-center justify-start text-title_unselected text-black" }, title),
                closeButton ? closeButton : (React.createElement(Img, { light: closeImg, dark: closeImgDark, className: "size-48 iconCloseButton", onClick: function () { return setIsOpen(false); } })))),
            children)), modalRoot.current);
};
export var BottomSheetBody = function (_a) {
    var height = _a.height, children = _a.children, maxHeight = _a.maxHeight, className = _a.className;
    return (React.createElement("div", { className: classNames("relative flex flex-col overflow-hidden text-modal_news text-black", className), style: { height: height, maxHeight: "calc(100vh - 36vw)" } }, children));
};
