export default {
    chartWidth: 1800,
    titleFont: "500 46px MiSans",
    descriptionFont: "400 40px MiSans",
    lineHeight: 50,
    lineWidth: 232,
    barWidth: 50,
    maxBarHeight: 390,
    padding: 20,
    gap: 48,
    sectionGap: 64,
};
