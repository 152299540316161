var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import formatNum from "../basics/colorNum/formatNum";
import sankeyStyle from "./style";
var textSize = function (text, font) {
    if (!text)
        return { w: 0, h: 0, lines: 0 };
    var lineWidth = sankeyStyle.lineWidth, lineHeight = sankeyStyle.lineHeight;
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    if (!ctx)
        return { w: 0, h: 0, lines: 0 };
    ctx.font = font;
    var textWidth = ctx.measureText(text).width;
    // Single line text
    if (textWidth <= lineWidth) {
        return { w: textWidth, h: lineHeight, lines: 1 };
    }
    // Multi-line text calculation
    var chars = text.split("");
    var totalWidth = 0;
    var lines = 1;
    var currentLineWidth = 0;
    chars.forEach(function (char) {
        var charWidth = ctx.measureText(char).width;
        if (currentLineWidth + charWidth > lineWidth) {
            lines++;
            currentLineWidth = charWidth;
        }
        else {
            currentLineWidth += charWidth;
        }
        totalWidth = Math.max(totalWidth, currentLineWidth);
    });
    return {
        w: totalWidth,
        h: lines * lineHeight,
        lines: lines,
    };
};
export var boxSize = function (data) {
    if (!data.value)
        return { w: 0, h: 0, lines: [0, 0, 0, 0] };
    var titleFont = sankeyStyle.titleFont, descriptionFont = sankeyStyle.descriptionFont;
    // Calculate text sizes
    var titleSize = textSize(data.name, titleFont);
    var valueSize = textSize(formatNum({ num: data.value, short: true }), titleFont);
    var chgText = data.chgPct
        ? "\u540C\u6BD4" + formatNum({
            num: data.chgPct,
            pct: true,
            plus: true,
            decimal: Math.abs(data.chgPct) < 1 ? 1 : 1,
        })
        : "";
    var chgSize = textSize(chgText, descriptionFont);
    var grossText = data.grossmargin
        ? "\u6BDB\u5229" + formatNum({
            num: data.grossmargin,
            pct: true,
            decimal: 0,
        })
        : "";
    var grossSize = textSize(grossText, descriptionFont);
    // Calculate box dimensions
    var textBoxWidth = Math.max(titleSize.w, valueSize.w, chgSize.w, grossSize.w);
    var textBoxHeight = titleSize.h + valueSize.h + chgSize.h + grossSize.h;
    // Determine final dimensions based on type
    return {
        w: textBoxWidth,
        h: textBoxHeight,
        lines: [titleSize.lines, valueSize.lines, chgSize.lines, grossSize.lines],
    };
};
// 过滤子项 TODO 黑名单需要从配置文件中读取
var blacklist = ["其他", "其它", "平衡项目"];
export var filterChild = function (section, // 传入的section
top, // 保留前三项
negative) {
    if (top === void 0) { top = 3; }
    // 筛选value最大的N项，其余归为"其他"
    var sortedItems = Object.entries(section).sort(function (a, b) { return Math.abs(b[1].value || 0) - Math.abs(a[1].value || 0); });
    var topN = sortedItems
        .filter(function (_a) {
        var _ = _a[0], item = _a[1];
        return !blacklist.some(function (keyword) { return item.name.includes(keyword); });
    })
        .slice(0, top);
    var others = sortedItems.filter(function (_a) {
        var _ = _a[0];
        return !topN.some(function (_a) {
            var key = _a[0];
            return key === _;
        });
    });
    // 重构section只保留前三项加其它
    Object.keys(section).forEach(function (key) { return delete section[key]; });
    topN.forEach(function (_a) {
        var key = _a[0], value = _a[1];
        section[key] = value;
    });
    if (others.length > 0) {
        var othersValue = others.reduce(function (sum, _a) {
            var _ = _a[0], item = _a[1];
            return sum + (item.value || 0);
        }, 0);
        var othersName = "其它";
        section["其它"] = {
            value: othersValue,
            name: othersName,
            size: boxSize({
                value: othersValue,
                name: othersName,
            }),
            negative: negative,
        };
    }
};
// 获取每个数据的框
export var positionData = function (data) {
    var maxAbsValue = Object.values(data).reduce(function (max, item) {
        if (!item.value)
            return max;
        // Check item.value
        if (Math.abs(item.value) > max) {
            max = Math.abs(item.value);
        }
        // Check nested child values
        if (item.child) {
            Object.values(item.child).forEach(function (child) {
                if (Math.abs(child.value || 0) > max) {
                    max = Math.abs(child.value || 0);
                }
            });
        }
        return max;
    }, 0);
    var barRatio = sankeyStyle.maxBarHeight / maxAbsValue;
    // Calculate sizes for each item and add to data
    Object.keys(data).forEach(function (key) {
        var item = data[key];
        var size = boxSize(item);
        data[key] = __assign(__assign({}, item), { size: size });
        // Calculate sizes for child items if they exist
        if (item.child) {
            Object.keys(item.child).forEach(function (childKey) {
                var childItem = item.child[childKey];
                var childSize = boxSize(childItem);
                data[key].child[childKey] = __assign(__assign({}, childItem), { size: childSize });
            });
        }
    });
    return { data: data, barRatio: barRatio };
};
// 计算列最大宽度
export var maxWidth = function (data) {
    var maxWidth = 0;
    data.forEach(function (sectionData) {
        if (sectionData) {
            Object.values(sectionData).forEach(function (blockData) {
                if (blockData.size && blockData.size.w > maxWidth) {
                    maxWidth = blockData.size.w;
                }
            });
        }
    });
    return maxWidth;
};
// 绘制文字描述
export var drawText = function (_a) {
    var _b, _c, _d, _e, _f;
    var child = _a.child, ctx = _a.ctx, align = _a.align, color = _a.color, x = _a.x, y = _a.y, x1 = _a.x1, y1 = _a.y1;
    var boxWidth = x1 - x;
    var boxHeight = y1 - y;
    var alignRatio = align === "left" ? 0 : align === "mid" ? 0.5 : 1;
    var totalLines = ((_b = child.size) === null || _b === void 0 ? void 0 : _b.lines.reduce(function (sum, line) { return sum + line; }, 0)) || 0;
    var totalHeight = totalLines * sankeyStyle.lineHeight;
    var currentY = sankeyStyle.lineHeight - 6;
    var drawTextLines = function (text, lines, style) {
        if (style) {
            if (style.fillStyle)
                ctx.fillStyle = style.fillStyle;
            if (style.font)
                ctx.font = style.font;
        }
        if (lines === 1) {
            var textWidth = ctx.measureText(text).width;
            ctx.fillText(text, x + (boxWidth - textWidth) * alignRatio, y + (boxHeight - totalHeight) / 2 + currentY);
        }
        else {
            var chars = text.split("");
            var charsPerLine = Math.ceil(chars.length / lines);
            for (var i = 0; i < chars.length; i += charsPerLine) {
                var lineText = chars.slice(i, i + charsPerLine).join("");
                var textWidth = ctx.measureText(lineText).width;
                ctx.fillText(lineText, x + (boxWidth - textWidth) * alignRatio, y + (boxHeight - totalHeight) / 2 + currentY);
                currentY += sankeyStyle.lineHeight;
            }
            return;
        }
        currentY += sankeyStyle.lineHeight;
    };
    // Draw title
    drawTextLines(child.name, ((_c = child.size) === null || _c === void 0 ? void 0 : _c.lines[0]) || 0, {
        fillStyle: color.black,
        font: sankeyStyle.titleFont,
    });
    // Draw value
    drawTextLines(formatNum({
        num: child.value || 0,
        short: true,
        decimal: 1,
    }), ((_d = child.size) === null || _d === void 0 ? void 0 : _d.lines[1]) || 0);
    // Draw change percentage
    if (child.chgPct)
        drawTextLines("\u540C\u6BD4" + formatNum({
            num: child.chgPct,
            pct: true,
            plus: true,
            decimal: 1,
        }), ((_e = child.size) === null || _e === void 0 ? void 0 : _e.lines[2]) || 0, {
            fillStyle: color.gray,
            font: sankeyStyle.descriptionFont,
        });
    if (child.grossmargin)
        drawTextLines("\u6BDB\u5229" + formatNum({
            num: child.grossmargin,
            pct: true,
            decimal: 0,
        }), ((_f = child.size) === null || _f === void 0 ? void 0 : _f.lines[3]) || 0, {
            fillStyle: color.gray,
            font: sankeyStyle.descriptionFont,
        });
};
// 绘制节点
export var drawBlock = function (_a) {
    var _b, _c;
    var ctx = _a.ctx, child = _a.child, color = _a.color, align = _a.align, x = _a.x, y = _a.y, x1 = _a.x1, barRatio = _a.barRatio;
    if (!child.value) {
        child.bar = {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            lCum: 0,
            rCum: 0,
        };
        return 0;
    }
    if (align === "left" || align === "right") {
        // 画柱子
        var barHeight = Math.max(5, Math.abs(child.value) * barRatio);
        var boxHeight = Math.max(barHeight, ((_b = child.size) === null || _b === void 0 ? void 0 : _b.h) || 0);
        var barY = y + (boxHeight - barHeight) / 2;
        var barX = align === "right" ? x : x1 - sankeyStyle.barWidth;
        child.bar = {
            x: barX,
            y: barY,
            w: sankeyStyle.barWidth,
            h: barHeight,
            lCum: 0,
            rCum: 0,
        };
        ctx.fillStyle =
            child.value * (child.negative ? -1 : 1) > 0 ? color.red : color.green;
        ctx.fillRect(barX, barY, sankeyStyle.barWidth, barHeight);
        // 写字
        drawText({
            child: child,
            ctx: ctx,
            align: align === "right" ? "left" : "right",
            color: color,
            x: align === "right" ? x + sankeyStyle.barWidth + sankeyStyle.padding : x,
            y: y,
            x1: align === "right"
                ? x1
                : x1 - sankeyStyle.barWidth - sankeyStyle.padding,
            y1: y + boxHeight,
        });
        return boxHeight;
    }
    else {
        var textHeight = ((_c = child.size) === null || _c === void 0 ? void 0 : _c.h) || 0;
        var barHeight = Math.max(5, Math.abs(child.value) * barRatio);
        // 写字
        drawText({
            child: child,
            ctx: ctx,
            align: "mid",
            color: color,
            x: x,
            y: align === "top" ? y : y + barHeight + sankeyStyle.padding,
            x1: x1,
            y1: align === "top"
                ? y + textHeight
                : y + barHeight + sankeyStyle.padding + textHeight,
        });
        var barX = x + (x1 - x - sankeyStyle.barWidth) / 2;
        var barY = align === "top" ? y + textHeight + sankeyStyle.padding : y;
        child.bar = {
            x: barX,
            y: barY,
            w: sankeyStyle.barWidth,
            h: barHeight,
            lCum: 0,
            rCum: 0,
        };
        ctx.fillStyle =
            child.value * (child.negative ? -1 : 1) > 0 ? color.red : color.green;
        ctx.fillRect(barX, barY, sankeyStyle.barWidth, barHeight);
        return textHeight + sankeyStyle.padding + barHeight;
    }
};
// 绘制连线
export var drawLink = function (ctx, leftBar, rightBar, height, color) {
    var startX = 0;
    var startY0 = 0;
    var startY1 = 0;
    var endX = 0;
    var endY0 = 0;
    var endY1 = 0;
    if (leftBar.rCum + height <= leftBar.h) {
        startX = leftBar.x + leftBar.w; // 左侧柱子的右边缘
        startY0 = leftBar.y + leftBar.rCum; // 左侧柱子的顶部
    }
    else {
        startX = leftBar.x + leftBar.w; // 左侧柱子的右边缘
        startY0 = leftBar.y + Math.max(0, leftBar.h - height); // 左侧柱子的顶部（倒退）
    }
    if (rightBar.lCum + height <= rightBar.h) {
        endX = rightBar.x; // 右侧柱子的左边缘
        endY0 = rightBar.y + rightBar.lCum; // 右侧柱子的顶部
    }
    else {
        endX = rightBar.x; // 右侧柱子的左边缘
        endY0 = rightBar.y + Math.max(0, rightBar.h - height); // 右侧柱子的顶部（倒退）
    }
    startY1 = Math.min(startY0 + height, leftBar.y + leftBar.h);
    endY1 = Math.min(endY0 + height, rightBar.y + rightBar.h);
    var midX = (startX + endX) / 2;
    ctx.beginPath();
    ctx.moveTo(startX, startY0);
    // Draw top curve
    ctx.bezierCurveTo(midX, startY0, midX, endY0, endX, endY0);
    // Draw right line
    ctx.lineTo(endX, endY1);
    // Draw bottom curve
    ctx.bezierCurveTo(midX, endY1, midX, startY1, startX, startY1);
    ctx.closePath();
    ctx.globalAlpha = 0.2;
    ctx.fillStyle = color;
    ctx.fill();
    ctx.globalAlpha = 1.0;
    leftBar.rCum += height;
    rightBar.lCum += height;
};
