var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import { useParams } from "react-router-dom";
import { isApp } from "../../lib/utils";
import { history } from "../../routes";
import { getFinreportQuick } from "../../service";
import clsx from "clsx";
import { Img } from "../../components/basics/img";
import formatNum from "../../components/basics/colorNum/formatNum";
import IncomeSankey from "../../components/reportSankey/income/income";
import BalanceSankey from "../../components/reportSankey/balance/balance";
import CashFlowSankey from "../../components/reportSankey/cashFlow/cashFlow";
import IncomeSankeyF from "../../components/reportSankey/income/income_f";
import BalanceSankeyF from "../../components/reportSankey/balance/balance_f";
import html2canvas from "html2canvas";
import { BottomSheet, BottomSheetBody, } from "../../components/bottomSheet/bottomSheet";
import toast from "react-hot-toast";
import { iosAndroid } from "../../lib/appOc";
import is from "../../lib/ua";
import useDarkMode from "../../components/useSessionStorageState/useDarkMode";
var Card = function (_a) {
    var _b;
    var title = _a.title, data = _a.data, _c = _a.isFinance, isFinance = _c === void 0 ? false : _c, _d = _a.sharing, sharing = _d === void 0 ? false : _d, fullScreen = _a.fullScreen, isDark = _a.isDark, _e = _a.setFullScreen, setFullScreen = _e === void 0 ? function () { } : _e;
    var _f = useState({
        width: "100%",
        height: "auto",
        transform: { x: 0, y: -30, scale: 1.2 },
    }), style = _f[0], setStyle = _f[1];
    var touchRef = useRef({
        isDragging: false,
        isScaling: false,
        startX: 0,
        startY: 0,
        startDistance: 0,
        startScale: 1.2,
        lastTransform: { x: 0, y: -30, scale: 1.2 },
        startCenterX: 0,
        startCenterY: 0,
    });
    var handleTouchStart = function (e) {
        if (e.touches.length === 1) {
            // Single touch - dragging
            touchRef.current.isDragging = true;
            touchRef.current.startX =
                e.touches[0].clientX - touchRef.current.lastTransform.x;
            touchRef.current.startY =
                e.touches[0].clientY - touchRef.current.lastTransform.y;
        }
        else if (e.touches.length === 2) {
            // Double touch - scaling and moving
            touchRef.current.isScaling = true;
            var touch1 = e.touches[0];
            var touch2 = e.touches[1];
            // Calculate initial distance for scaling
            var distance = Math.hypot(touch2.clientX - touch1.clientX, touch2.clientY - touch1.clientY);
            touchRef.current.startDistance = distance;
            touchRef.current.startScale = touchRef.current.lastTransform.scale;
            // Calculate initial center point for moving
            var centerX = (touch1.clientX + touch2.clientX) / 2;
            var centerY = (touch1.clientY + touch2.clientY) / 2;
            touchRef.current.startCenterX =
                centerX - touchRef.current.lastTransform.x;
            touchRef.current.startCenterY =
                centerY - touchRef.current.lastTransform.y;
        }
    };
    var handleTouchMove = function (e) {
        if (!fullScreen)
            return;
        if (touchRef.current.isDragging && e.touches.length === 1) {
            // Handle single finger dragging
            var x = e.touches[0].clientX - touchRef.current.startX;
            var y = e.touches[0].clientY - touchRef.current.startY;
            touchRef.current.lastTransform = __assign(__assign({}, touchRef.current.lastTransform), { x: x,
                y: y });
            setStyle(function (prev) { return (__assign(__assign({}, prev), { transform: touchRef.current.lastTransform })); });
        }
        else if (touchRef.current.isScaling && e.touches.length >= 2) {
            // Handle two finger scaling and moving
            var touch1 = e.touches[0];
            var touch2 = e.touches[1];
            // Calculate new distance for scaling
            var distance = Math.hypot(touch2.clientX - touch1.clientX, touch2.clientY - touch1.clientY);
            // Calculate scale
            var scale = Math.min(Math.max((distance / touchRef.current.startDistance) *
                touchRef.current.startScale, 0.5), 3);
            // Calculate new center point for moving
            var centerX = (touch1.clientX + touch2.clientX) / 2;
            var centerY = (touch1.clientY + touch2.clientY) / 2;
            var x = centerX - touchRef.current.startCenterX;
            var y = centerY - touchRef.current.startCenterY;
            touchRef.current.lastTransform = __assign(__assign({}, touchRef.current.lastTransform), { x: x,
                y: y,
                scale: scale });
            setStyle(function (prev) { return (__assign(__assign({}, prev), { transform: touchRef.current.lastTransform })); });
        }
    };
    var handleTouchEnd = function () {
        touchRef.current.isDragging = false;
        touchRef.current.isScaling = false;
    };
    return (React.createElement("div", { className: "relative mb-48 box-border flex w-full flex-col rounded-r24 bg-modal_white py-32" },
        React.createElement(Img, { light: require("./assets/" + (title === "资产负债表" ? "balanceBg" : title === "利润表" ? "incomeBg" : "cashFlowBg") + ".png"), dark: require("./assets/" + (title === "资产负债表" ? "balanceBg-dark" : title === "利润表" ? "incomeBg-dark" : "cashFlowBg-dark") + ".png"), className: "absolute right-0 top-0 z-0 box-border w-678" }),
        React.createElement("div", { className: clsx("relative px-32 text-title_selected", title === "利润表" && "text-[#4F251D] dark:text-orange", title === "资产负债表" && "text-[#653B0E] dark:text-[#FF9626]", title === "现金流量表" && "text-[#132D5C] dark:text-[#68A4FE]") }, title),
        React.createElement("div", { className: clsx(fullScreen === title
                ? "fixed inset-0 z-10 flex items-center justify-center bg-bg_page"
                : "relative px-12 pt-48"), onClick: function () { return setFullScreen(fullScreen ? null : title); } },
            fullScreen === title && (React.createElement("div", { className: "absolute bottom-64 left-0 right-0 text-center text-number_small font-medium text-gray_1" }, "\u70B9\u51FB\u9000\u51FA\u5168\u5C4F")),
            React.createElement("div", { onTouchStart: handleTouchStart, onTouchMove: handleTouchMove, onTouchEnd: handleTouchEnd, style: fullScreen === title
                    ? {
                        width: style.width,
                        height: style.height,
                        transform: "translate(" + style.transform.x + "px, " + style.transform.y + "px) scale(" + style.transform.scale + ") rotate(90deg)",
                    }
                    : {} },
                title === "利润表" && isFinance && (React.createElement(IncomeSankeyF, { data: data.sangji })),
                title === "利润表" && !isFinance && (React.createElement(IncomeSankey, { data: data.sangji })),
                title === "资产负债表" && isFinance && (React.createElement(BalanceSankeyF, { data: data.sangji })),
                title === "资产负债表" && !isFinance && (React.createElement(BalanceSankey, { data: data.sangji })),
                title === "现金流量表" && React.createElement(CashFlowSankey, { data: data.sangji })),
            !sharing && !fullScreen && (React.createElement("div", { className: "px-20 pt-24 text-remark text-gray_1" }, "\u70B9\u51FB\u53EF\u67E5\u770B\u5927\u56FE"))),
        ((data.des && data.des.length > 0 && data.des[0]) ||
            data.keys.length > 0) && (React.createElement("div", { className: "relative flex flex-col gap-24 px-32" },
            React.createElement("div", { className: "relative flex items-center gap-12 pt-48 text-subtitle_strategy_name" },
                React.createElement("div", { className: "absolute bottom-6 left-0 h-15 w-200", style: {
                        background: "linear-gradient(to right, rgba(140, 146, 217, 0), rgba(140, 146, 217, 0.5))",
                    } }),
                React.createElement("img", { src: require("./assets/target.png"), alt: "icon", className: "size-36" }),
                React.createElement("div", { className: "relative text-black" }, "\u5173\u952E\u6307\u6807")),
            ((_b = data.des) === null || _b === void 0 ? void 0 : _b[0]) && (React.createElement("div", { className: "relative text-modal_news text-gray_1" },
                React.createElement("span", { className: "font-hello text-[#8C92D9]" }, "\u4E3B\u8981\u53D8\u52A8\u539F\u56E0"),
                React.createElement("span", { className: "text-gray_2" }, "\uFF5C"),
                data.des[0])),
            data.keys && (React.createElement("div", { style: {
                    background: isDark
                        ? "linear-gradient(to bottom, rgba(140,146,217,0.2), rgba(140,146,217,0))"
                        : "linear-gradient(to bottom, rgb(236,241,255),  rgb(236,241,255,0))",
                }, className: "rounded-r24 px-24 py-16 text-remark text-orange" },
                React.createElement("div", { className: "grid grid-cols-3 gap-16" }, data.keys.map(function (key, index) {
                    var pct = false;
                    var suffix = "";
                    if (key.name.endsWith("率") || key.name.endsWith("比")) {
                        pct = true;
                    }
                    if (key.name.endsWith("天数")) {
                        suffix = "天";
                    }
                    return (React.createElement("div", { key: index, className: "flex w-full flex-col items-center justify-center gap-4 whitespace-nowrap py-24" },
                        React.createElement("div", { className: "text-sub_subtitle_unselected text-black" }, key.name),
                        React.createElement("div", { className: "text-modal_button text-black" },
                            formatNum({
                                num: key.value / (pct ? 100 : 1),
                                short: true,
                                pct: pct,
                            }),
                            suffix),
                        React.createElement("div", { className: clsx("text-remark", key.chgPct
                                ? key.chgPct < 0
                                    ? "text-green"
                                    : "text-red"
                                : "text-gray_1") },
                            "\u540C\u6BD4",
                            key.chgPct
                                ? formatNum({
                                    num: key.chgPct,
                                    pct: true,
                                    plus: true,
                                })
                                : " --")));
                }))))))));
};
var FinancialReportPreview = function () {
    var _a = useParams(), _b = _a.stock, stock = _b === void 0 ? "" : _b, _c = _a.reportPeriod, reportPeriod = _c === void 0 ? "" : _c;
    var code = stock.replace("_", ".");
    var reportPeriodText = reportPeriod === null || reportPeriod === void 0 ? void 0 : reportPeriod.replace("1231", "年报").replace("0331", "一季报").replace("0630", "中报").replace("0930", "三季报");
    var _d = useState(null), data = _d[0], setData = _d[1];
    var bodyRef = useRef(null);
    var imgRef = useRef(null);
    var _e = useState(true), isTop = _e[0], setIsTop = _e[1];
    var _f = useState(false), isLoading = _f[0], setIsLoading = _f[1];
    var _g = useState(false), showMenu = _g[0], setShowMenu = _g[1];
    var _h = useState(false), sharing = _h[0], setSharing = _h[1];
    var _j = useState(null), previewImage = _j[0], setPreviewImage = _j[1];
    var _k = useState(null), fullScreen = _k[0], setFullScreen = _k[1];
    var isDark = useDarkMode();
    useEffect(function () {
        setIsLoading(true);
        setPreviewImage(null);
        getFinreportQuick({ code: code, reportPeriod: reportPeriod || "20240630" })
            .success(function (res) {
            var data = res.data.datas;
            setData(data);
            setIsLoading(false);
        })
            .start();
    }, [stock, reportPeriod]);
    useEffect(function () {
        var element = bodyRef.current;
        if (!element)
            return;
        var handleScroll = function () {
            setIsTop(element.scrollTop < 50);
        };
        element.addEventListener("scroll", handleScroll);
        return function () {
            element.removeEventListener("scroll", handleScroll);
        };
    }, [data]);
    useEffect(function () {
        if (sharing && !previewImage)
            generateImage();
    }, [sharing]);
    var generateImage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var canvas, base64Data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!imgRef.current)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, html2canvas(imgRef.current, {
                            useCORS: true,
                            scale: 2,
                            backgroundColor: "#fff",
                        })];
                case 2:
                    canvas = _a.sent();
                    base64Data = canvas.toDataURL("image/png");
                    setPreviewImage(base64Data);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    console.error("Failed to generate image:", err_1);
                    toast.error("生成图片失败");
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "relative h-screen" },
        !isApp && !fullScreen && (React.createElement("div", { className: "fixed left-0 top-0 z-20 box-border flex h-88 w-full items-center justify-between bg-orange px-36 transition-colors duration-300", style: {
                backgroundColor: isTop ? "transparent" : "rgba(255, 127, 91, 0.7)",
                backdropFilter: isTop ? "none" : "blur(8px)",
            } },
            React.createElement("div", { onClick: function () { return history.goBack(); } },
                React.createElement("img", { src: require("../../assets/images/newHeaderBar/back-dark.png"), alt: "\u8FD4\u56DE", className: "h-48 w-48" })),
            React.createElement("div", { className: "text-subtitle_strategy_name text-white" }, "\u8D22\u62A5\u9884\u89C8"),
            React.createElement("div", { onClick: function () { return setSharing(!sharing); } },
                React.createElement("img", { src: require("./assets/share.png"), alt: "\u5206\u4EAB", className: "h-48 w-48" })))),
        React.createElement("div", { ref: bodyRef, className: "h-full overflow-y-auto overscroll-y-auto" },
            React.createElement("div", { ref: imgRef, className: "relative box-border items-center bg-bg_primary_blue px-36 pb-48 dark:bg-bg_page" },
                React.createElement("img", { src: require("./assets/head.png"), alt: "\u8D22\u62A5\u9884\u89C8", className: clsx("absolute left-0 top-0 z-0 w-full", isApp ? "-translate-y-176" : "-translate-y-88") }),
                React.createElement("div", { className: clsx("absolute left-0 top-450 h-152 w-full", isApp ? "-translate-y-176" : "-translate-y-88"), style: {
                        background: isDark
                            ? "linear-gradient(to top, rgb(16,17,22), rgba(16,17,22,0))"
                            : "linear-gradient(to top, rgb(245,248,255), rgba(245,248,255,0))",
                    } }),
                React.createElement("div", { className: clsx("relative flex w-full items-center justify-center gap-12 pb-48", isApp ? "pt-32" : "pt-120") },
                    React.createElement("div", { className: "text-title_large text-white" }, data === null || data === void 0 ? void 0 : data.stockName),
                    React.createElement("div", { className: "grow text-title_unselected text-white opacity-80" }, code),
                    React.createElement("div", { onClick: function () { return setShowMenu(!showMenu); }, className: "rounded-12 flex h-56 w-206 items-center justify-center gap-12 overflow-hidden rounded-full border-1 border-solid border-[rgba(255,255,255,0.7)] bg-[rgba(255,255,255,0.2)] text-remark text-white" },
                        React.createElement("div", { className: "relative text-center" },
                            React.createElement("div", { className: clsx("absolute left-0 right-0 transition-all duration-300", isLoading
                                    ? "translate-y-0 opacity-100"
                                    : "-translate-y-full opacity-0") }, "\u52A0\u8F7D\u4E2D\u2026"),
                            React.createElement("div", { className: clsx("transition-all duration-300", isLoading
                                    ? "translate-y-full opacity-0"
                                    : "translate-y-0 opacity-100") }, reportPeriodText)),
                        !sharing && (React.createElement("img", { src: require("./assets/down.png"), alt: "\u4E0B\u62C9", className: "size-24" })))),
                (data === null || data === void 0 ? void 0 : data.pdf) && !sharing && (React.createElement("img", { onClick: function () {
                        var url = "/pdf?file=" + data.pdf;
                        if (isApp) {
                            iosAndroid("appInfo", {}, function (res) {
                                if (res.isPad && res.version > 2022061500) {
                                    iosAndroid("padTo", { pdf: data.pdf });
                                }
                                else {
                                    if (is.iPhone) {
                                        window.open(data.pdf);
                                    }
                                    else {
                                        location.href = data.pdf;
                                    }
                                }
                            });
                        }
                        else {
                            window.location.href = url;
                        }
                    }, src: require("./assets/pdf.png"), alt: "pdf", className: "relative w-full pb-16" })),
                (data === null || data === void 0 ? void 0 : data.income) && (React.createElement(Card, { title: "\u5229\u6DA6\u8868", data: data.income, isFinance: data.isFinance, sharing: sharing, fullScreen: fullScreen, setFullScreen: setFullScreen, isDark: isDark })),
                (data === null || data === void 0 ? void 0 : data.cashFlow) && (React.createElement(Card, { title: "\u73B0\u91D1\u6D41\u91CF\u8868", data: data.cashFlow, isFinance: data.isFinance, sharing: sharing, fullScreen: fullScreen, setFullScreen: setFullScreen, isDark: isDark })),
                (data === null || data === void 0 ? void 0 : data.balance) && (React.createElement(Card, { title: "\u8D44\u4EA7\u8D1F\u503A\u8868", data: data.balance, isFinance: data.isFinance, sharing: sharing, fullScreen: fullScreen, setFullScreen: setFullScreen, isDark: isDark })),
                !sharing && (React.createElement("div", { onClick: function () { return setSharing(!sharing); }, className: "mt-48 box-border flex h-88 w-full items-center justify-center rounded-full bg-tab_shadow_gray px-32 text-list_name text-gray_1 dark:bg-bg_primary_blue" },
                    React.createElement("svg", { viewBox: "0 0 40 40", className: "mr-8 size-40 fill-gray_1 stroke-gray_1" },
                        React.createElement("path", { d: "M6.04158 19.0274L14.3478 24.0562C14.7422 24.2815 14.9832 24.7031 14.9775 25.1572C14.9718 25.6114 14.7202 26.0267 14.3203 26.242C13.9204 26.4574 13.4352 26.4389 13.0528 26.1937L2.64158 19.8924C2.23849 19.6488 2.00658 19.1992 2.0417 18.7295C2.07682 18.2598 2.37299 17.8497 2.80783 17.6687L35.3078 4.09617C35.7323 3.91931 36.2196 3.98977 36.5766 4.27963C36.9335 4.56949 37.1025 5.03194 37.0166 5.48367L31.3228 35.4837C31.2456 35.8905 30.9719 36.2327 30.5919 36.3974C30.2119 36.5621 29.7751 36.528 29.4253 36.3062L18.8703 29.6137C18.287 29.2443 18.1135 28.472 18.4828 27.8887C18.8522 27.3053 19.6245 27.1318 20.2078 27.5012L29.2103 33.2087L34.1266 7.29992L6.04158 19.0274Z", strokeWidth: "0.4" }),
                        React.createElement("path", { d: "M15.7891 26.191V36.4998C15.7891 37.1901 15.2294 37.7498 14.5391 37.7498C13.8487 37.7498 13.2891 37.1901 13.2891 36.4998V25.616C13.289 25.2506 13.4489 24.9035 13.7266 24.666L28.9216 11.656C29.261 11.3658 29.7294 11.2786 30.1505 11.4274C30.5716 11.5762 30.8813 11.9383 30.963 12.3774C31.0447 12.8165 30.886 13.2658 30.5466 13.556L15.7891 26.1898V26.191Z", strokeWidth: "0.4" })),
                    "\u5206\u4EAB\u56FE\u7247")),
                sharing && (React.createElement("div", { className: "relative mt-48 flex items-center justify-between gap-18 rounded-r24 bg-tab_shadow_gray p-32 dark:bg-bg_primary_blue" },
                    React.createElement("div", { className: "flex flex-col gap-12 text-remark text-black" },
                        React.createElement("img", { src: require("./assets/logo.png"), alt: "logo", className: "w-260" }),
                        "\u6311\u80A1\u7968\uFF0C\u9009\u57FA\u91D1\uFF0C\u5C31\u4E0A\u829D\u58EB\u8D22\u5BCC\uFF01"),
                    React.createElement("img", { src: require("./assets/qrCode.png"), alt: "qr", className: "size-100 rounded-r12 border-6 border-solid border-[#FFC94F33]" })))),
            React.createElement(BottomSheet, { isOpen: showMenu, setIsOpen: setShowMenu, title: "\u9009\u62E9\u62A5\u544A\u671F" },
                React.createElement(BottomSheetBody, null,
                    React.createElement("div", { className: "flex h-400 flex-col gap-12 overflow-y-auto py-24" }, data === null || data === void 0 ? void 0 : data.periods.map(function (period) {
                        var periodText = period
                            .replace("0331", "一季报")
                            .replace("0630", "中报")
                            .replace("0930", "三季报")
                            .replace("1231", "年报");
                        return (React.createElement("div", { key: period, className: clsx("flex h-88 w-full shrink-0 items-center justify-center rounded-r12 text-list_name", period === reportPeriod
                                ? "bg-orange text-white"
                                : "bg-tab_shadow_gray text-gray_1 dark:bg-modal_bg_blue"), onClick: function () {
                                history.replace("/financialReportPreview/" + stock + "/" + period);
                                setShowMenu(false);
                            } }, periodText));
                    })))),
            React.createElement(BottomSheet, { isOpen: sharing, setIsOpen: setSharing, title: "\u957F\u6309\u4E0B\u56FE\u4FDD\u5B58\u56FE\u7247" },
                React.createElement(BottomSheetBody, null,
                    React.createElement("div", { className: "flex flex-col items-center overflow-y-auto" }, previewImage ? (React.createElement("img", { src: previewImage, alt: "\u9884\u89C8\u56FE", className: "w-full rounded-r24" })) : (React.createElement("div", { className: "flex h-200 w-full items-center justify-center text-gray_1" }, "\u6B63\u5728\u751F\u6210\u56FE\u7247..."))))))));
};
export default FinancialReportPreview;
