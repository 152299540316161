var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import { wechatShare } from "./lib/wx";
import { history, routes } from "./routes";
// import NewUser from './components/newUser/index';
import HomePage from './pages/homepage/index';
import { isApp, upSavePointer } from './lib/utils';
import { getHasSign } from './service';
import * as qs from 'qs';
import store from './store';
import './pages/app.css';
import { routesConfig } from './routesConfig';
import generateHX_url from './stockAccountConfig';
import { judgeHbToken } from './aggApi/hbApi/util';
import NewUser from './components/newUser/index';
import getVconsole from './lib/vconsole';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            newUser: null,
            routeData: null,
            isHb: judgeHbToken() ? true : false
        };
        //首页路由
        _this.showBarArr = ['/',
            '/SearchIndustry',
            '/ViewingInformation',
            '/SevenEleven',
            '/chooseindex',
            '/fundHome',
            // '/FavoriteHomeReady',
            '/favorite',
            '/me',
            '/stock-picker'];
        _this.unlisten = function () {
        }; // 初始值为一个空函数
        return _this;
    }
    App.prototype.componentWillUnmount = function () {
        this.unlisten();
    };
    App.prototype.componentDidMount = function () {
        var _this = this;
        getVconsole();
        //跳转华西开户
        this.goHXcreatAccount();
        //积分 是否签到
        this.getHasSign();
        //618活动 拼团
        // this.get618_giveNo()
        //路由监听
        this.clearBrowseHistory();
        this.downBrowseHistory(location);
        this.upSavePointer(location);
        this.unlisten = history.listen(function (route) {
            //保存用户浏览路径
            _this.downBrowseHistory(route);
            _this.setState({
                routeData: route,
            });
            //埋点 pointerArt
            _this.upSavePointer(route);
        });
        if (window.localStorage.getItem('token')) {
            var userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null;
            this.setState({
                newUser: userInfo
            });
        }
        wechatShare(window.sessionStorage.getItem('wechatShare_Url'), {
            title: "\u829D\u58EB\u8D22\u5BCC",
            link: window.location.origin + '/',
            desc: '您的好友邀请您使用芝士财富',
            imgUrl: 'https://oss.zsxg.cn/cheese/icon.png'
        });
    };
    App.prototype.goHXcreatAccount = function () {
        var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (query.open_HX_creatAccount == 'creat') {
            generateHX_url({ type: 'creatAccount' });
        }
    };
    App.prototype.getHasSign = function () {
        if (isApp) {
            return;
        }
        getHasSign().success(function (res) {
            var resData = res.data.datas;
            if (!resData) {
                store.dispatch({
                    type: 'change_showInregral',
                    value: true
                });
            }
        }).start();
    };
    // get618_giveNo() {
    //         const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    //         let chapterId = query.chapterId
    //         if (query.giveNo_618) {
    //                 let httpData = chapterId ? { giveNo: String(query.giveNo_618), chapterId: String(chapterId) } :
    //                         { giveNo: String(query.giveNo_618) }
    //                 getParty618_unLock(httpData).success(res => {
    //                 }).start()
    //         }
    // }
    App.prototype.upSavePointer = function (route) {
        var routesArr = __spreadArrays(routesConfig);
        var nowItem = null;
        routesArr.forEach(function (item) {
            if (item.path == route.pathname) {
                nowItem = item;
            }
        });
        if (!(nowItem && nowItem.name)) {
            return;
        }
        var pointerArt = nowItem && nowItem.pointerArt ? nowItem.pointerArt.split(',') : null;
        var query = qs.parse(route.search, { ignoreQueryPrefix: true });
        var upSaveData = [
            { name: 'event_name', value: '页面访问' },
            { name: 'event_ext1', value: nowItem.name }
        ];
        if (pointerArt) {
            pointerArt.forEach(function (item, index) {
                upSaveData.push({
                    name: 'event_ext' + (index + 2),
                    value: String(query[item])
                });
            });
        }
        upSavePointer(upSaveData);
    };
    App.prototype.downBrowseHistory = function (route) {
        var storeRouteData = store.getState().routeLast_pathName;
        var routeHistory = __spreadArrays(storeRouteData);
        if (history.action == 'PUSH') {
            routeHistory.push(route.pathname);
            try {
                var iconCloseButton = document.getElementsByClassName('iconCloseButton')[0];
                if (iconCloseButton && history.location.pathname.substring(0, 23) != '/financialReportPreview') {
                    iconCloseButton.click();
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        else if (history.action == 'POP') {
            if (routeHistory && routeHistory.length > 0) {
                routeHistory.pop();
            }
            else {
                routeHistory.push(route.pathname);
            }
        }
        else {
            if (routeHistory && routeHistory.length > 0) {
                routeHistory[routeHistory.length - 1] = route.pathname;
            }
            else {
                routeHistory.push(route.pathname);
            }
        }
        store.dispatch({
            type: 'change_lastRouter_pathName',
            value: routeHistory.slice(-10)
        });
    };
    App.prototype.clearBrowseHistory = function () {
        store.dispatch({
            type: 'change_lastRouter_pathName',
            value: []
        });
    };
    App.prototype.render = function () {
        var showNewUser = true;
        var pathName = history.location.pathname;
        return (
        //如果是华宝 就不弹 或者datas为null
        React.createElement("div", null,
            Boolean(!this.state.isHb) &&
                React.createElement(NewUser, null),
            Boolean(pathName && Boolean(this.showBarArr.includes(pathName))) && React.createElement(HomePage, null),
            routes));
    };
    return App;
}(React.PureComponent));
export default App;
