// @ts-ignore
import loadable from '@loadable/component';
import FinancialReportPreview from './pages/financialReportPreview';
var projectD = loadable(function () { return import('./pages/singlePages/projectD'); });
var institutionalResearchDetail = loadable(function () { return import("./pages/stock/institutionalResearchDetail"); });
var Search = loadable(function () { return import(/* webpackChunkName: "Search" */ './pages/search/index'); });
var CourseSearch = loadable(function () { return import(/* webpackChunkName: "Search" */ './pages/courseSearch/index'); });
var Stock = loadable(function () { return import(/* webpackChunkName: "Stock" */ './pages/stock/index'); });
var Module_Subject_stock = loadable(function () { return import('./pages/stock/A_module_Subject'); });
var A_module_share = loadable(function () { return import('./pages/stock/A_module_share'); });
// const IndexShare = loadable( ()=> import('./pages/indexStock/share') )
var StockHkShare = loadable(function () { return import('./pages/stockHk/share'); });
var StockHk = loadable(function () { return import(/* webpackChunkName: "Stock" */ './pages/stockHk/index'); });
var StockSetting = loadable(function () { return import(/* webpackChunkName: "StockSetting" */ './pages/stockNewSetting/index'); });
var About = loadable(function () { return import(/* webpackChunkName: "About" */ './pages/about/index'); });
var Version = loadable(function () { return import(/* webpackChunkName: "Version" */ './pages/version/index'); });
var Clear = loadable(function () { return import(/* webpackChunkName: "Clear" */ './pages/clear/index'); });
var VDashboard = loadable(function () { return import(/* webpackChunkName: "VDashboard" */ './pages/VDashboard/index'); });
var Market = loadable(function () { return import(/* webpackChunkName: "Market" */ './pages/market/index'); });
var SearchIndustry = loadable(function () { return import(/* webpackChunkName: "Market" */ './pages/market/SearchIndustry'); });
var ViewingInformation = loadable(function () { return import(/* webpackChunkName: "Market" */ './pages/market/viewingInformation'); });
var SevenEleven = loadable(function () { return import(/* webpackChunkName: "Market" */ './pages/market/sevenEleven'); });
var Favorite = loadable(function () { return import('./pages/favorite/index'); });
var FavoriteReady = loadable(function () { return import('./pages/favorite/favorite'); });
var ManagerGroup = loadable(function () { return import('./pages/favorite/managerGroup'); });
var UploadImg = loadable(function () { return import('./pages/favorite/uploadImg'); });
var Me = loadable(function () { return import('./pages/me/index'); });
var UserIntegral = loadable(function () { return import('./pages/integral/index'); });
var UserIntegralData = loadable(function () { return import('./pages/integral/integralData/index'); });
var IntegralNewUser = loadable(function () { return import('./pages/integral/newUser'); });
var IndexBoard = loadable(function () { return import(/* webpackChunkName: "IndexBoard" */ './pages/indexBoard/index'); });
var Sort = loadable(function () { return import(/* webpackChunkName: "Sort" */ './pages/sort/index'); });
var Filters = loadable(function () { return import(/* webpackChunkName: "Filters" */ './pages/filters/index'); });
var FiltersResult = loadable(function () { return import(/* webpackChunkName: "FiltersResult" */ './pages/filtersResult/index'); });
var CompareSearch = loadable(function () { return import(/* webpackChunkName: "CompareSearch" */ './pages/compareSearch/index'); });
var CompareResult = loadable(function () { return import(/* webpackChunkName: "CompareResult" */ './pages/compareResult/index'); });
var Pay = loadable(function () { return import(/* webpackChunkName: "Pay" */ './pages/pay/index'); });
var Pay_coupon = loadable(function () { return import('./pages/pay_coupon/index'); });
var Pay_overCoupon = loadable(function () { return import('./pages/pay_coupon/overCoupon'); });
var Diff = loadable(function () { return import(/* webpackChunkName: "Diff" */ './pages/diff/index'); });
var SortSetting = loadable(function () { return import(/* webpackChunkName: "SortSetting" */ './pages/sortSetting/index'); });
var PushSetting = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/pushSetting/index'); });
var PushSettingHk = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/pushSettingHk/index'); });
var PushSettingAll = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/pushSetingAll/index'); });
var DepthData = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/depthData/index'); });
var depthDataHk = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/depthDataHk/index'); });
var FundIndex = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/fundDetails/index'); });
var FundShare = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/fundDetails/share'); });
var ReportList = loadable(function () { return import(/* webpackChunkName: "articles" */ './pages/reportList/index'); });
var Articles = loadable(function () { return import(/* webpackChunkName: "Articles" */ './pages/articles/index'); });
var ArticlesNew = loadable(function () { return import(/* webpackChunkName: "Articles" */ './pages/articlesNew/index'); });
var ArticleInfo = loadable(function () { return import(/* webpackChunkName: "ArticleInfo" */ './pages/articleInfo/index'); });
var ArticleNotion = loadable(function () { return import(/* webpackChunkName: "ArticleNotion" */ './pages/articleNotion/index'); });
var OpenAccount = loadable(function () { return import(/* webpackChunkName: "OpenAccount" */ './pages/openAccount/index'); });
var PdfPage = loadable(function () { return import(/* webpackChunkName: "PdfPagefv " */ './pages/pdfPage/index'); });
var Weekly = loadable(function () { return import(/* webpackChunkName: "Weekly" */ './pages/weekly/index'); });
var Daily = loadable(function () { return import(/* webpackChunkName: "Daily" */ './pages/daily/index'); });
var DailyPaper = loadable(function () { return import(/* webpackChunkName: "Daily" */ './pages/dailyPaper/index'); });
//做新页面指向日报页面
var DailyPape = loadable(function () { return import(/* webpackChunkName: "Daily" */ './pages/dailyPaper/index'); });
var HbStock = loadable(function () { return import(/* webpackChunkName: "HbStock" */ './pages/hbStock/index'); });
var HbPDFLists = loadable(function () { return import(/* webpackChunkName: "HbPDFLists" */ './pages/HbPDFList/index'); });
var HbPdfPage = loadable(function () { return import(/* webpackChunkName: "HbPdfPage" */ './pages/hbPdfPage/index'); });
var HBIndustryIndex = loadable(function () { return import(/* webpackChunkName: "HBIndustryIndex" */ './pages/hbindustryIndex/index'); });
var ActivityPage = loadable(function () { return import(/* webpackChunkName: "HBIndustryIndex" */ './pages/activityPage/index'); });
var FundManager = loadable(function () { return import('./pages/fundManager/index'); });
var FundHome = loadable(function () { return import('./pages/fundHome/index'); });
var InstitutionalList = loadable(function () { return import('./pages/institutionalList/index'); });
var InstitutionalDetail = loadable(function () { return import('./pages/institutionalDetail/index'); });
var testWhite = loadable(function () { return import('./pages/testWhite/index'); });
var ChooseNew = loadable(function () { return import(/* webpackChunkName: "ChooseList" */ './pages/ChooseNew/index'); });
var ChooseSuperdex = loadable(function () { return import(/* webpackChunkName: "ChooseList" */ './pages/ChooseSuperdex/index'); });
var choose = loadable(function () { return import('./pages/choose/index'); });
// 选股策略被更换
var chooseSuper = loadable(function () { return import('./pages/chooseSuper/index'); });
var HappyNewYear = loadable(function () { return import('./pages/HappyNewYear/index'); });
var HappyNewYearCont = loadable(function () { return import('./pages/happyNewyearContent/index'); });
var ChooseResult = loadable(function () { return import(/* webpackChunkName: "ChooseResult" */ './pages/chooseresult/index'); });
// 选股结果页被替换
var ChooseResultSpuer = loadable(function () { return import(/* webpackChunkName: "ChooseResultSpuer" */ './pages/chooseresultSpuer/index'); });
var ChooseList = loadable(function () { return import(/* webpackChunkName: "ChooseList" */ './pages/chooseList/index'); });
// 选股页被更换
var chooseindex = loadable(function () { return import(/* webpackChunkName: "chooseindex" */ './pages/chooseIndex/index'); });
// 我的选股页被更换
var chooseMeList = loadable(function () { return import(/* webpackChunkName: "chooseMeList" */ './pages/chooseMeList/index'); });
var chooseMeListIndex = loadable(function () { return import('./pages/stockPicker/chooseMeList/index'); });
var stockPickerIndex = loadable(function () { return import('./pages/stockPicker/index'); });
var stockPickerNewIndex = loadable(function () { return import('./pages/stockPickerNew/index'); });
var stockCompareNew = loadable(function () { return import('./pages/stockPickerNew/stockCompare/index'); });
var rankingStockSelectionIndex = loadable(function () { return import('./pages/stockPickerNew/rankingStockSelection/index'); });
var rankingStockSelectionDetailIndex = loadable(function () { return import('./pages/stockPickerNew/rankingStockSelectionDetail/index'); });
var chooseStockPickerIndex = loadable(function () { return import('./pages/stockPicker/choose/index'); });
var resultStockPickerIndex = loadable(function () { return import('./pages/stockPicker/result/index'); });
var PushSettingList = loadable(function () { return import('./pages/pushSetting/components/PriceList'); });
// 财报速览
// @ts-ignore
var financialOverview = loadable(function () { return import('./pages/financialOverview'); });
/*拼团活动*/
var groupBuyIndex = loadable(function () { return import('./pages/groupBuy/index'); });
//app开户或交易跳转
var userAccount = loadable(function () { return import('./pages/me/openAccount/index'); });
var selectAdd = loadable(function () { return import(/* webpackChunkName: "selectAdd" */ './pages/selectAdd/index'); });
var hbWhere = loadable(function () { return import(/* webpackChunkName: "selectAdd" */ './pages/hbWhere/index'); });
var NoticeForId = loadable(function () { return import(/* webpackChunkName: "NoticeForId" */ './pages/noticeForId/index'); });
var NoticeList = loadable(function () { return import(/* webpackChunkName: "NoticeList" */ './pages/noticeList/index'); });
var PDFList = loadable(function () { return import(/* webpackChunkName: "PDFList" */ './pages/PDFList/index'); });
var DupontCompare = loadable(function () { return import(/* webpackChunkName: "DupontCompare" */ './pages/dupontCompare/index'); });
var PaySuccess = loadable(function () { return import(/* webpackChunkName: "PaySuccess" */ './pages/paySuccess/index'); });
var MeSetting = loadable(function () { return import(/* webpackChunkName: "MeSetting" */ './pages/meSetting/index'); });
var BindPhone = loadable(function () { return import(/* webpackChunkName: "BindPhone" */ './pages/bindPhone/index'); });
var MeNotification = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/meNotification/index'); });
var orderList = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/order/index'); });
var industry = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/industry/index'); });
var app_userPage = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/app_userPage/index'); });
var app_userPrice = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/app_userPrice/index'); });
var app_userIndex = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/app_userIndex/index'); });
var NewYearPage = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/newYearPage/index'); });
var PriceDetail = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/market/priceDetail'); });
var DownPage = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/downPage/index'); });
var zhisir = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/zhisir/index'); });
var RankList = loadable(function () { return import('./pages/rankList/index'); });
var RankList_content = loadable(function () { return import('./pages/rankList/content/index'); });
var NewUserParty = loadable(function () { return import('./pages/newUserParty/index'); });
var reportListNew = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/reportListNew/index'); });
var blArticleInfo = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/blArticleInfo/index'); });
var chooseFund = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/chooseFund/index'); });
var chooseNewFund = loadable(function () { return import(/* webpackChunkName: "MeNotification" */ './pages/ChooseNewFund/index'); });
var indexStock = loadable(function () { return import(/* webpackChunkName: "indexStock" */ './pages/indexStock/index'); });
var componentStockList = loadable(function () { return import(/* webpackChunkName: "indexStock" */ './pages/indexStock/list/componentStock'); });
var componentFundList = loadable(function () { return import(/* webpackChunkName: "indexStock" */ './pages/indexStock/list/componentFund'); });
var componentChildList = loadable(function () { return import(/* webpackChunkName: "indexStock" */ './pages/indexStock/list/componentChild'); });
var Party_618 = loadable(function () { return import('./pages/party_618/index'); });
// const cheeseParty20240618 = loadable( ()=>import('./pages/cheeseParty20240618/index') )
var Party_1001 = loadable(function () { return import('./pages/party_1001/index'); });
var Party_618_luck = loadable(function () { return import('./pages/party_618/luckyDog'); });
// @ts-ignore
var northDates = loadable(function () { return import(/* webpackChunkName: "northDates" */ './pages/northDates'); });
// @ts-ignore
var southDates = loadable(function () { return import(/* webpackChunkName: "southDates" */ './pages/southDates'); });
// @ts-ignore
var newDateStock = loadable(function () { return import(/* webpackChunkName: "northDates" */ './pages/newDatesStock'); });
// @ts-ignore
var veryHoldInfo = loadable(function () { return import(/* webpackChunkName: "northDates" */ './pages/veryHoldInfo'); });
// @ts-ignore
var veryHoldInfoHb = loadable(function () { return import(/* webpackChunkName: "northDates" */ './pages/veryHoldInfoHb'); });
// @ts-ignore
var articlesPage = loadable(function () { return import(/* webpackChunkName: "articlesPage" */ './pages/articlesPage'); });
var ProfitComputer = loadable(function () { return import('./pages/fundProfitComputer/index'); });
var FundManCompare = loadable(function () { return import('./pages/fundManCompare/index'); });
var FundCompare = loadable(function () { return import('./pages/fundCompare/index'); });
var stockCompare = loadable(function () { return import('./pages/stockCompare/index'); });
var kpiCommen = loadable(function () { return import('./pages/fundDetails/kpiCommen/index'); });
var articles_Search = loadable(function () { return import('./pages/articles/search'); });
var articles_SearchAnswer = loadable(function () { return import('./pages/articles/answer'); });
var articles_SearchAllQuestion = loadable(function () { return import('./pages/articles/allQuestion'); });
//App课程小节
var subjectSection = loadable(function () { return import('./pages/subjectPage/sectionIndex'); });
var subjectParty = loadable(function () { return import('./pages/subjectPage/subjectParty'); });
var subjectAddress = loadable(function () { return import('./pages/subjectPage/subAddress'); });
//投票
var Vote = loadable(function () { return import('./pages/vote/index'); });
//研报与公告
var researchAndNotice = loadable(function () { return import('./pages/researchAndNotice/index'); });
//拉取用户信息授权
var sendAuthorize = loadable(function () { return import('./lib/sendAuthorize/index'); });
//Vip赠送卡片
var vipGiftCardHome = loadable(function () { return import('./pages/vipGiftCard/index'); });
var vipGiftCard = loadable(function () { return import('./pages/vipGiftCard/getCard'); });
var vipGiftCardPay = loadable(function () { return import('./pages/vipGiftCard/payCard'); });
var vipGiftCardEditor = loadable(function () { return import('./pages/vipGiftCard/editorCard'); });
var stockNotice = loadable(function () { return import('./pages/stock/stockNotice'); });
var stockNoticeHk = loadable(function () { return import('./pages/stockHk/stockNotice'); });
// const party_1212 = loadable( ()=>import('./pages/party_1212/index') )
// const party_2024_3Year = loadable( ()=>import('./pages/party_24_3year/index') )
var cheeseParty20240618 = loadable(function () { return import('./pages/cheeseParty20240618/index'); });
var cheeseParty20241001 = loadable(function () { return import('./pages/cheeseParty20241001/index'); });
var party_23_618 = loadable(function () { return import('./pages/party_23_618/index'); });
var party_24_1111 = loadable(function () { return import('./pages/party_24_1111/index'); });
var party_24_1212 = loadable(function () { return import('./pages/party_24_1212/index'); });
// const party_23_1212 = loadable( ()=>import('./pages/party_23_1212/index') )
var ImportantEvents = loadable(function () { return import('./pages/importantEvents/index'); });
var NewUserGuide = loadable(function () { return import('./pages/NewUserGuide/index'); });
var NewUserLuckDog = loadable(function () { return import('./pages/NewUserGuide/lottery/index'); });
var ArticleI_iframe = loadable(function () { return import('./pages/articlesNew/articleI_iframe'); });
var NewscastShare = loadable(function () { return import('./pages/market/newsCast/share'); });
var NewscastCont = loadable(function () { return import('./pages/market/newsCast/content'); });
var InstitutionalPositions = loadable(function () { return import('./pages/institutionalPositions/index'); });
var InstitutionalPositionCont = loadable(function () { return import('./pages/institutionalPositions/content'); });
var Module_shareVideoBuild = loadable(function () { return import('./pages/stock/shareVideoBuild/index'); });
var Segments = loadable(function () { return import('./pages/segments/index'); });
var HbAggPage = loadable(function () { return import('./pages/hbAggPage/index'); });
// @ts-ignore
var NotFound = loadable(function () { return import('./pages/notFound'); });
//开户活动
var StockAccountParty = loadable(function () { return import('./pages/stockAccountParty/index'); });
// 新股申购
var newStockDetail = loadable(function () { return import('./pages/newStockDetail/index'); });
//同行对比
var IndustryCompare = loadable(function () { return import('./pages/stock/industryCompare/allScreen'); });
//积分商城路由
export var userIntegral_url = '/userIntegralPopTwo';
//添加新页面
var receiveVip = loadable(function () { return import('./pages/newpage/index'); });
//行业研究
var IndustryAnalysis = loadable(function () { return import('./pages/market/industry/industryAnalysis'); });
//行业研究详情页
var IndustryDetail = loadable(function () { return import('./pages/market/industry/industryDetail'); });
//行业分析集合页面
var IndustryGroup = loadable(function () { return import('./pages/market/industry/industryGroup'); });
//行业分析详情页面
var IndustryGroupDetail = loadable(function () { return import('./pages/market/industry/industryGroupDetail'); });
//ETF看板
var IndustryGroupETF = loadable(function () { return import('./pages/market/industry/industryGroupETF'); });
//咨询详情
var SeekAdviceDetail = loadable(function () { return import('./pages/market/seekAdvice/seekAdviceDetail'); });
//直播地址
var LiveStream = loadable(function () { return import('./pages/market/liveStream/index'); });
//直播历史回顾
var LiveStreamHistory = loadable(function () { return import('./pages/market/liveStream/liveStreamHistory'); });
//用户入群
var WeChatGroup = loadable(function () { return import('./pages/weChatGroup/index'); });
//ETF详情页
var EtfDetails = loadable(function () { return import(/* webpackChunkName: "pushSetting" */ './pages/etfDetails/index'); }); // 股价异动页面
var SwingStocks = loadable(function () { return import('./pages/market/swingStocks/swingStock'); });
var Demo = loadable(function () { return import('./pages/demo/demo'); });
//课程卡片
var course = loadable(function () { return import('./pages/course/index'); });
//课程列表
var CourseList = loadable(function () { return import('./pages/course/courseList/index'); });
//课程详情页
var CourseDetails = loadable(function () { return import('./pages/course/courseDetails/index'); });
//课程练习
var ChapterExercises = loadable(function () { return import('./pages/course/chapterExercises/index'); });
//结业证书
var CertificateCompletion = loadable(function () { return import('./pages/course/certificateCompletion/index'); });
//沪深港通
var StockConnectDetail = loadable(function () { return import('./pages/market/stockConnectDetail/index'); });
// 评论测试
var CommentSectionTestPage = loadable(function () { return import('./pages/CommentSectionTestPage/index'); });
var CommentDetailPage = loadable(function () { return import('./pages/CommentDetailPage/index'); });
// 专栏
var ColumnPage = loadable(function () { return import('./pages/ColumnPage/index'); });
// 专栏详情页
var ColumnArticleDetailPage = loadable(function () { return import('./pages/ColumnArticleDetailPage/index'); });
// 消息盒子
var MessageBoxPage = loadable(function () { return import('./pages/MessageBoxPage/index'); });
export var routesConfig = [
    {
        name: '股票行情',
        path: '/',
        component: Market,
        cacheWhen: 'always',
    },
    {
        name: '股票产业',
        path: '/SearchIndustry',
        component: SearchIndustry,
        cacheWhen: 'always',
    },
    {
        name: '股票咨询',
        path: '/ViewingInformation',
        component: ViewingInformation,
        cacheWhen: 'always',
    },
    {
        name: '股票7*24',
        path: '/SevenEleven',
        component: SevenEleven,
        cacheWhen: 'always',
    },
    {
        name: '基金首页',
        path: '/fundHome',
        component: FundHome,
        cacheWhen: 'always'
    }, {
        name: '自选',
        path: '/favorite',
        component: FavoriteReady,
        cacheWhen: 'always'
    }, {
        name: '自选-管理分组',
        path: '/favoriteManagerGroup',
        component: ManagerGroup,
    }, {
        name: '自选-图片批量添加',
        path: '/uploadImg',
        component: UploadImg,
    }, {
        path: '/articles',
        component: Articles,
        cacheWhen: 'always'
    }, {
        name: '宝典主页',
        path: '/ArticlesNew',
        component: ArticlesNew,
        cacheWhen: 'always',
    }, {
        name: '宝典文章详情页',
        path: '/articleI_iframe',
        component: ArticleI_iframe,
        pointerArt: 'id'
    }, {
        name: '操作指南-搜索',
        path: '/articles_Search',
        component: articles_Search,
        cacheWhen: 'always'
    }, {
        name: '操作指南-全部问题',
        path: '/articles_AllQuestion',
        component: articles_SearchAllQuestion,
        cacheWhen: 'forward'
    }, {
        name: '操作指南-答案',
        path: '/articles_SearchAnswer',
        component: articles_SearchAnswer,
    }, {
        name: '我的',
        path: '/me',
        component: Me,
        cacheWhen: 'always'
    }, {
        name: '用户积分',
        path: userIntegral_url,
        component: UserIntegral,
    }, {
        name: '用户积分',
        path: '/userIntegralPop',
        component: UserIntegral,
    }, {
        name: '用户积分记录',
        path: '/userIntegralData',
        component: UserIntegralData,
    }, {
        name: '积分分享落地页',
        path: '/integralNewUser',
        component: IntegralNewUser,
    },
    {
        name: '芝士会员',
        path: '/pay',
        component: Pay,
    }, {
        name: '股票详情页',
        path: '/stock',
        component: Stock,
        cacheWhen: 'forward',
        pointerArt: 'stockCode'
    }, {
        name: '机构调研详情页',
        path: '/institutionalResearchDetail',
        component: institutionalResearchDetail,
        cacheWhen: 'forward',
        pointerArt: 'stockCode'
    }, {
        name: '大事提醒',
        path: '/importantEvents',
        component: ImportantEvents,
        cacheWhen: 'forward',
        cacheKey: 'ImportantEvents'
    }, {
        name: '公司公告',
        path: '/stockNotice',
        component: stockNotice,
    }, {
        name: '港股详情页',
        path: '/stockHK',
        component: StockHk,
        cacheWhen: 'forward',
        pointerArt: 'stockCode'
    }, {
        name: '公司公告',
        path: '/stockNoticeHK',
        component: stockNoticeHk,
    }, {
        name: '基金详情页',
        path: '/fundDetails',
        component: FundIndex,
        cacheWhen: 'forward',
        pointerArt: 'code',
        cacheKey: 'fundDetails'
    },
    {
        name: '基金分享',
        path: '/fundShare',
        component: FundShare,
        pointerArt: 'code'
    },
    {
        name: 'etf详情页',
        path: '/etfDetails',
        component: EtfDetails,
        cacheWhen: 'forward',
        pointerArt: 'etfCode',
        cacheKey: 'etfDetails'
    },
    {
        name: '基金经理详情页',
        path: '/fundManager',
        component: FundManager,
        cacheWhen: 'forward',
        pointerArt: 'managerId'
    }, {
        name: '指数详情页',
        path: '/indexStock',
        component: indexStock,
        cacheWhen: 'forward',
        pointerArt: 'stockCode',
        cacheKey: 'indexStock'
    }, {
        name: '课程介绍页',
        path: '/subjectParty',
        component: subjectParty,
    }, {
        name: '课程-填写收货地址',
        path: '/subjectAddress',
        component: subjectAddress,
    }, {
        name: '课程-拓展阅读',
        path: '/subjectSection',
        component: subjectSection,
    }, {
        name: '搜索页',
        path: '/search',
        component: Search,
        cacheWhen: 'always'
    }, {
        path: '/about',
        component: About,
    }, {
        name: '选股-首页',
        path: '/ChooseSuperdex',
        component: ChooseSuperdex,
    }, {
        name: '选股-配置页',
        path: '/chooseSuper',
        component: chooseSuper,
        cacheWhen: 'forward',
        cacheKey: 'chooseSuper'
    }, {
        // 换新
        name: '选股-结果页',
        path: '/chooseResultSpuer',
        component: resultStockPickerIndex,
        cacheWhen: 'forward',
        cacheKey: 'ChooseResultSpuer'
    }, {
        name: '选基-结果页',
        path: '/chooseResult',
        component: ChooseResult,
        cacheWhen: 'forward',
        cacheKey: 'chooseResult'
    }, {
        path: '/version',
        component: Version,
    }, {
        name: '重要股东明细',
        path: '/veryHoldInfo',
        component: veryHoldInfo,
        cacheWhen: 'forward',
        cacheKey: 'veryHoldInfo'
    }, {
        path: '/veryHoldInfoHb',
        component: veryHoldInfoHb,
        cacheWhen: 'forward',
        cacheKey: 'veryHoldInfoHb'
    }, {
        path: '/clear',
        component: Clear,
    }, {
        name: '分享视频配置',
        path: '/module_shareVideoBuild',
        component: Module_shareVideoBuild,
    }, {
        name: '课程操作页',
        path: '/module_Subject_stock',
        component: Module_Subject_stock,
    }, {
        name: 'App分享',
        path: '/A_module_share',
        component: A_module_share,
    },
    // {
    //     name:'App指数分享',
    //     path:'/IndexShare',
    //     component: IndexShare,
    // },
    {
        name: 'App港股分享',
        path: '/stockHkShare',
        component: StockHkShare,
    },
    {
        path: '/dashboard',
        component: VDashboard,
    }, {
        name: '指数看板',
        path: '/indexBoard',
        component: IndexBoard,
        cacheWhen: 'forward',
        cacheKey: 'IndexBoard',
    }, {
        name: '优惠券列表页',
        path: '/pay_coupon',
        component: Pay_coupon,
    }, {
        name: '优惠券列表页(已过期)',
        path: '/Pay_overCoupon',
        component: Pay_overCoupon,
    }, {
        name: '股价提醒',
        path: '/notificationSetting',
        component: PushSetting,
    }, {
        name: '全部股价提醒',
        path: '/pushSettingAll',
        component: PushSettingAll,
    }, {
        name: '提醒列表',
        path: '/pushSettingList',
        component: PushSettingList
    }, {
        name: '股票详情页排序',
        path: '/sortSetting',
        component: SortSetting,
    }, {
        name: '我的-股票',
        path: '/openAccount',
        component: OpenAccount,
    }, {
        path: '/noticeList',
        component: NoticeList,
    }, {
        path: '/noticeForId',
        component: NoticeForId,
    }, {
        path: '/pdf-List',
        component: PDFList,
    }, {
        path: '/pdf',
        component: PdfPage,
    }, {
        path: '/reportList',
        component: ReportList,
    }, {
        name: '周报',
        path: '/weekly',
        component: Weekly,
        pointerArt: 'pushDate'
    }, {
        name: '账号与推送',
        path: '/meSetting',
        component: MeSetting,
    }, {
        name: '绑定手机号',
        path: '/bindphone',
        component: BindPhone,
    }, {
        name: '公众号通知管理',
        path: '/meNotification',
        component: MeNotification,
    }, {
        name: '日报(旧)',
        path: '/daily',
        component: Daily,
        cacheWhen: 'forward',
        cacheKey: 'daily'
    }, {
        name: '日报',
        path: '/dailyPaper',
        component: DailyPaper,
        cacheWhen: 'forward',
        cacheKey: 'dailyPaper',
        pointerArt: 'date'
    },
    {
        name: '新日报',
        path: '/dailyPape',
        component: DailyPape,
        cacheWhen: 'forward',
        cacheKey: 'dailyPape',
        pointerArt: 'date'
    },
    {
        name: 'A股详情页(华宝)',
        path: '/hbStock',
        component: HbStock,
    }, {
        path: '/HbPDFList',
        component: HbPDFLists,
    }, {
        path: '/hbPdfPage',
        component: HbPdfPage,
    }, {
        path: '/hbindustryIndex',
        component: HBIndustryIndex,
    }, {
        path: '/kpiCommen',
        component: kpiCommen,
    }, {
        name: '财报详情页',
        path: '/depthData',
        component: DepthData,
        pointerArt: 'code'
    }, {
        path: '/depthDataHk',
        component: depthDataHk,
    }, {
        name: '榜单页',
        path: '/rankList',
        component: RankList,
        cacheWhen: 'forward',
    }, {
        name: '榜单详情页',
        path: '/rankList_content',
        component: RankList_content,
        cacheWhen: 'forward',
        cacheKey: 'rankList_content',
        pointerArt: 'type,name'
    }, {
        path: '/newYearPage',
        component: NewYearPage,
    }, {
        name: '涨跌分布',
        path: '/priceDetail',
        component: PriceDetail,
        cacheWhen: 'forward',
    }, {
        path: '/downPage',
        component: DownPage,
    }, {
        name: '华宝条件单',
        path: '/hbWhere',
        component: hbWhere,
    }, {
        name: '我的订单',
        path: '/orderList',
        component: orderList,
    }, {
        path: '/industry',
        component: industry,
    }, {
        path: '/app_userPage',
        component: app_userPage,
    }, {
        path: '/app_userPrice',
        component: app_userPrice,
    }, {
        path: '/app_userIndex',
        component: app_userIndex,
    }, {
        path: '/zhisir',
        component: zhisir,
    }, {
        path: '/newUserParty',
        component: NewUserParty,
    }, {
        path: '/ChooseNew',
        component: ChooseNew,
    }, {
        name: '个股研报聚合',
        path: '/InstitutionalList',
        component: InstitutionalList,
        cacheWhen: 'forward',
        cacheKey: 'InstitutionalList',
        pointerArt: 'code'
    }, {
        name: '单个研报详情',
        path: '/InstitutionalDetail',
        component: InstitutionalDetail,
    }, {
        path: '/reportListNew',
        component: reportListNew,
    }, {
        name: '选基-配置页',
        path: '/chooseFund',
        component: chooseFund,
    }, {
        name: '选基-首页',
        path: '/chooseNewFund',
        component: chooseNewFund,
    }, {
        path: '/componentFundList',
        component: componentFundList,
        cacheWhen: 'forward',
    }, {
        path: '/componentStockList',
        component: componentStockList,
        cacheWhen: 'forward',
        cacheKey: 'componentStockList'
    }, {
        path: '/componentChildList',
        component: componentChildList,
    }, {
        path: '/northDates',
        component: northDates,
        cacheWhen: 'forward',
    }, {
        path: '/southDates',
        component: southDates,
        cacheWhen: 'forward',
    }, {
        name: '今日新股',
        path: '/newDateStock',
        component: newDateStock,
        cacheWhen: 'forward',
    }, {
        name: '基金定投计算器',
        path: '/profitComputer',
        component: ProfitComputer,
    }, {
        name: '基金经理对比',
        path: '/fundManCompare',
        component: FundManCompare,
        cacheWhen: 'forward',
        cacheKey: 'FundManCompare'
    }, {
        name: '基金对比',
        path: '/fundCompare',
        component: FundCompare,
        cacheWhen: 'forward',
    }, {
        name: '股票对比',
        path: '/stockCompare',
        component: stockCompare,
        cacheWhen: 'forward',
    }, {
        name: '股票对比',
        path: '/stockCompareNew',
        component: stockCompareNew,
        cacheWhen: 'forward',
    }, {
        name: '活动618',
        path: '/cheeseParty_618',
        component: Party_618,
        cacheWhen: 'forward',
    }, {
        path: '/testWhite',
        component: testWhite,
    }, {
        name: '活动1001',
        path: '/cheeseParty_1001',
        component: Party_1001,
    }, {
        name: '投票',
        path: '/vote',
        component: Vote,
        cacheWhen: 'forward',
    }, {
        name: '研报与公告',
        path: '/researchAndNotice',
        component: researchAndNotice,
        cacheWhen: 'forward',
        cacheKey: 'researchAndNotice'
    }, {
        name: '礼品卡-领取',
        path: '/vipGiftCard',
        component: vipGiftCard,
    }, {
        name: '礼品卡首页',
        path: '/vipGiftCardHome',
        component: vipGiftCardHome,
    }, {
        name: '礼品卡赠送页',
        path: '/vipGiftCardEditor',
        component: vipGiftCardEditor,
    }, {
        name: '礼品卡购买页',
        path: '/vipGiftCardPay',
        component: vipGiftCardPay,
    }, {
        path: '/sendAuthorize',
        component: sendAuthorize,
    },
    // {
    //     name:'2022双十二活动',
    //     path:'/cheeseParty20221212',
    //     component: party_1212,
    // },
    {
        name: '2023-618活动',
        path: '/cheeseParty20230618',
        component: party_23_618,
    },
    {
        name: '2024-双十一活动',
        path: '/cheeseParty20241111',
        component: party_24_1111,
    },
    {
        name: '2024-双十二活动',
        path: '/cheeseParty20241212',
        component: party_24_1212,
    },
    // {
    //     name:'2024-3年会员活动',
    //     path:'/cheeseParty20243Year',
    //     component: party_2024_3Year,
    // },
    // {
    //     name:'2023双十二活动',
    //     path:'/cheeseParty20231212',
    //     component: party_23_1212,
    // },
    {
        name: '2024-5年会员活动',
        path: '/cheeseParty20240618',
        component: cheeseParty20240618,
    },
    {
        name: '2024-国庆活动',
        path: '/cheeseParty20241001',
        component: cheeseParty20241001,
    },
    {
        name: '新用户引导页面',
        path: '/newUserGuide',
        component: NewUserGuide,
        cacheWhen: 'forward',
        cacheKey: 'newUserGuide'
    }, {
        name: '新用户引导抽奖页',
        path: '/newUserLuckDog',
        component: NewUserLuckDog,
    }, {
        path: '/newscastShare',
        component: NewscastShare,
    }, {
        name: '新闻详情',
        path: '/newscastCont',
        component: NewscastCont,
    }, {
        path: '/StockSetting',
        component: StockSetting,
    }, {
        path: '/choose',
        component: choose,
    }, {
        path: '/courseSearch',
        component: CourseSearch,
    }, {
        path: '/sort',
        component: Sort,
    }, {
        path: '/filters-result',
        component: FiltersResult,
    }, {
        path: '/compare-search',
        component: CompareSearch,
    }, {
        path: '/compare-result',
        component: CompareResult,
    }, {
        path: '/diff',
        component: Diff,
    }, {
        path: '/notificationSettingHk',
        component: PushSettingHk,
    }, {
        path: '/article',
        component: ArticleInfo,
    }, {
        path: '/articleN',
        component: ArticleNotion,
    }, {
        path: '/dupont-compare',
        component: DupontCompare,
    }, {
        path: '/paySuccess',
        component: PaySuccess,
    }, {
        path: '/pdf-list',
        component: PDFList,
    }, {
        path: '/articlesPage',
        component: ArticlesNew,
        cacheWhen: 'forward'
    }, {
        path: '/activityPage',
        component: ActivityPage,
    }, {
        path: '/ChooseList',
        component: ChooseList,
    }, {
        path: '/selectAdd',
        component: selectAdd,
    }, {
        name: '选股-首页',
        path: '/chooseindexold',
        component: stockPickerIndex,
        cacheWhen: 'always',
    }, {
        name: '选股-首页',
        path: '/chooseindex',
        component: stockPickerNewIndex,
        cacheWhen: 'always',
    }, {
        name: '榜单选股',
        path: '/rankingStockSelectionIndex',
        component: rankingStockSelectionIndex,
        cacheWhen: 'always',
    }, {
        name: '榜单选股-详情',
        path: '/rankingStockSelectionDetailIndex',
        component: rankingStockSelectionDetailIndex,
        cacheWhen: 'always',
    }, {
        name: '拼团活动-首页',
        path: '/group-buy-index',
        component: groupBuyIndex,
        cacheWhen: 'always',
    }, {
        name: '新选股-首页',
        path: '/stock-picker',
        component: stockPickerIndex,
        cacheWhen: 'always',
    }, {
        name: '新建选股',
        path: '/choose-stock-picker',
        component: chooseStockPickerIndex,
        cacheWhen: 'always',
    }, {
        name: '选股结果',
        path: '/result-stock-picker',
        component: resultStockPickerIndex,
        cacheWhen: 'always',
    }, {
        name: '我的选股',
        path: '/my-stock-picker',
        component: chooseMeListIndex,
    }, {
        name: '选股策略',
        path: '/chooseMeList',
        component: chooseMeList,
    }, {
        name: '开户活动',
        path: '/stockAccountParty',
        component: StockAccountParty,
        cacheWhen: 'forward',
    }, {
        name: '机构持仓',
        path: '/institutionalPositions',
        component: InstitutionalPositions,
        cacheWhen: 'forward',
        cacheKey: 'institutionalPositions'
    }, {
        name: '机构持仓详情',
        path: '/institutionalPositionCont',
        component: InstitutionalPositionCont,
        cacheWhen: 'forward',
        cacheKey: 'institutionalPositionCont'
    }, {
        name: '主营构成',
        path: '/segments',
        component: Segments,
        cacheWhen: 'forward',
        cacheKey: 'Segments'
    }, {
        name: '连携聚合页',
        path: '/hbAggP',
        component: HbAggPage,
        cacheWhen: 'forward',
        cacheKey: 'HbAggP'
    }, {
        name: '404',
        path: '/notfound',
        component: NotFound
    }, {
        name: '财报速览',
        path: '/fOverview',
        component: financialOverview,
    }, {
        name: '新股日历',
        path: '/newStockDetail',
        component: newStockDetail,
        cacheWhen: 'forward',
        cacheKey: 'newStockDetail'
    }, {
        name: '开户跳转',
        path: '/userAccount',
        component: userAccount,
    }, {
        name: '行业对比',
        path: '/IndustryCompare',
        component: IndustryCompare,
        cacheWhen: 'forward',
        cacheKey: 'IndustryCompare'
    },
    {
        name: '会员提示',
        path: '/receiveVip',
        component: receiveVip,
    },
    {
        name: '行业研究',
        path: '/IndustryAnalysis',
        component: IndustryAnalysis,
        cacheWhen: 'always',
    },
    {
        name: '行业研究详情页',
        path: '/IndustryDetail',
        component: IndustryDetail,
    },
    {
        name: '行业分析集合页',
        path: '/IndustryGroup',
        component: IndustryGroup,
        cacheWhen: 'always',
    },
    {
        name: '行业分析详情页',
        path: '/IndustryGroupDetail',
        component: IndustryGroupDetail,
        cacheWhen: 'always',
    },
    {
        name: 'ETF看板',
        path: '/IndustryGroupETF',
        component: IndustryGroupETF,
        cacheWhen: 'forward',
    },
    {
        name: '宣传图截图',
        path: '/projectD',
        component: projectD,
    },
    {
        name: '咨询详情',
        path: '/SeekAdviceDetail',
        component: SeekAdviceDetail,
    },
    {
        name: '直播',
        path: '/LiveStream',
        component: LiveStream,
    },
    {
        name: '直播订阅',
        path: '/LiveStreamHistory',
        component: LiveStreamHistory,
    },
    {
        name: '用户入群',
        path: '/weChatGroup',
        component: WeChatGroup,
    },
    {
        name: '股价异动',
        path: '/swingStocks',
        component: SwingStocks,
        cacheWhen: 'forward',
    },
    {
        name: '课程',
        path: '/course',
        component: course
    },
    {
        name: '课程列表',
        path: '/CourseList',
        component: CourseList,
        cacheWhen: 'forward',
        cacheKey: 'CourseList'
    },
    {
        name: '课程详情页',
        path: '/CourseDetails',
        component: CourseDetails,
        cacheWhen: 'forward',
        cacheKey: 'CourseDetails'
    },
    {
        name: '课程练习',
        path: '/ChapterExercises',
        component: ChapterExercises,
    },
    {
        name: '结业证书',
        path: '/CertificateCompletion',
        component: CertificateCompletion,
    },
    {
        name: '沪深港通详情页',
        path: '/StockConnectDetail',
        component: StockConnectDetail,
        cacheWhen: 'forward',
        cacheKey: 'StockConnectDetail'
    },
    {
        name: '财报预览',
        path: '/financialReportPreview/:stock/:reportPeriod',
        component: FinancialReportPreview,
        cacheWhen: 'forward',
        cacheKey: 'financialReportPreview',
    },
    {
        name: '评论组件测试',
        path: '/comment-test',
        component: CommentSectionTestPage,
        cacheWhen: 'always',
    },
    {
        name: '评论详情',
        path: '/comment-detail',
        component: CommentDetailPage,
        cacheWhen: 'forward',
        cacheKey: 'commentDetail'
    },
    {
        name: '专栏',
        path: '/column/:id',
        component: ColumnPage,
        cacheWhen: 'always',
        cacheKey: 'column',
        pointerArt: 'id'
    },
    {
        name: '专栏文章详情',
        path: '/column-article-detail/:articleId/:commId',
        component: ColumnArticleDetailPage,
        cacheWhen: 'forward',
        cacheKey: 'columnArticleDetail',
        pointerArt: 'articleId,commId'
    },
    {
        name: '消息盒子',
        path: '/messageBox',
        component: MessageBoxPage,
        cacheWhen: 'forward',
        cacheKey: 'messageBox',
    },
];
