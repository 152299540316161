import { useEffect, useState } from "react";
var useDarkMode = function () {
    var _a = useState(false), isDarkMode = _a[0], setIsDarkMode = _a[1];
    useEffect(function () {
        try {
            var mediaQuery_1 = window.matchMedia("(prefers-color-scheme: dark)");
            var handleChange_1 = function (e) { return setIsDarkMode(e.matches); };
            // 初始设置
            setIsDarkMode(mediaQuery_1.matches);
            // 监听变化
            mediaQuery_1.addEventListener("change", handleChange_1);
            // 清理
            return function () { return mediaQuery_1.removeEventListener("change", handleChange_1); };
        }
        catch (e) {
            //报错
            setIsDarkMode(false);
        }
    }, []);
    return isDarkMode;
};
export default useDarkMode;
