export default function formatNum(props) {
    var num = props.num;
    if (typeof num === "string") {
        num = parseFloat(num);
    }
    var prefix = "";
    var suffix = "";
    var decimal = Number.isInteger(props.decimal) ? props.decimal : 2;
    if (props.plus && num >= 0) {
        prefix = "+";
    }
    else if (num < 0) {
        prefix = "-";
        num = Math.abs(num);
    }
    if (props.pct) {
        suffix = "%";
        num *= 100;
        // Adjust decimal places for large percentages
        if (num > 1000) {
            decimal = 0;
        }
        else if (num > 100) {
            decimal = Number.isInteger(props.decimal) ? props.decimal : 1;
        }
    }
    else if (props.short) {
        if (num <= 10) {
            suffix = "";
        }
        else if (num <= 100) {
            decimal = 1;
            suffix = "";
        }
        else if (num <= 10000) {
            decimal = 0;
            suffix = "";
        }
        else if (num <= 100000) {
            num /= 10000;
            suffix = "万";
        }
        else if (num <= 1000000) {
            num /= 10000;
            decimal = 1;
            suffix = "万";
        }
        else if (num <= 100000000) {
            num /= 10000;
            decimal = 0;
            suffix = "万";
        }
        else if (num <= 1000000000) {
            num /= 100000000;
            suffix = "亿";
        }
        else if (num <= 10000000000) {
            num /= 100000000;
            decimal = 1;
            suffix = "亿";
        }
        else if (num <= 1000000000000) {
            num /= 100000000;
            decimal = 0;
            suffix = "亿";
        }
        else if (num <= 10000000000000) {
            num /= 1000000000000;
            suffix = "万亿";
        }
        else if (num <= 100000000000000) {
            num /= 1000000000000;
            decimal = 1;
            suffix = "万亿";
        }
        else {
            num /= 1000000000000;
            decimal = 0;
            suffix = "万亿";
        }
    }
    return "" + prefix + num.toFixed(decimal) + suffix;
}
