var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from "react";
import sankeyStyle from "../style";
import { drawBlock, drawLink, filterChild, maxWidth, positionData, } from "../sankey";
import useChartColors from "../useChartColors";
var IncomeSankeyF = function (_a) {
    var data = _a.data;
    var canvasRef = useRef(null);
    var color = useChartColors();
    var offCanvas = document.createElement("canvas");
    useEffect(function () {
        var _a, _b;
        if (!data)
            return;
        var lanes = ((_a = data.TOT_OPER_REV) === null || _a === void 0 ? void 0 : _a.child) &&
            Object.keys((_b = data.TOT_OPER_REV) === null || _b === void 0 ? void 0 : _b.child).length > 0
            ? 4
            : 3;
        var currentLane = 0;
        var laneWidth = sankeyStyle.chartWidth / lanes;
        // 初始化canvas
        var canvas = canvasRef.current;
        if (!canvas)
            return;
        var ctx = canvas.getContext("2d");
        if (!ctx)
            return;
        var offCtx = offCanvas.getContext("2d");
        if (!offCtx)
            return;
        // Set canvas size
        document.fonts.ready.then(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
            canvas.width = sankeyStyle.chartWidth;
            canvas.height = 3000;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            offCanvas.width = canvas.width;
            offCanvas.height = canvas.height;
            offCtx.clearRect(0, 0, canvas.width, canvas.height);
            // 处理数据
            var _v = positionData(data), positonedData = _v.data, barRatio = _v.barRatio;
            var currentX = 0;
            var currentY = 0;
            // 逐列绘制，然后再拼在一起
            // 1. 绘制营业收入明细
            if (((_a = positonedData.TOT_OPER_REV) === null || _a === void 0 ? void 0 : _a.child) &&
                Object.keys((_b = positonedData.TOT_OPER_REV) === null || _b === void 0 ? void 0 : _b.child).length > 0) {
                currentLane += 1;
                // 为了节约面积，找到边列的最大宽度，然后靠边对齐
                var thisLaneWidth_1 = Math.ceil(maxWidth([positonedData.TOT_OPER_REV.child])) +
                    sankeyStyle.padding +
                    sankeyStyle.barWidth;
                // 依次绘制节点
                filterChild(positonedData.TOT_OPER_REV.child, 3);
                Object.values(positonedData.TOT_OPER_REV.child).forEach(function (child) {
                    currentY += drawBlock({
                        ctx: offCtx,
                        child: child,
                        color: color,
                        align: "left",
                        x: currentX,
                        y: currentY,
                        x1: currentX + thisLaneWidth_1,
                        barRatio: barRatio,
                    });
                    currentY += sankeyStyle.gap;
                });
                currentY -= sankeyStyle.gap;
                // 居中添加到大画板上，并调整节点位置
                var offsetY_1 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_1);
                Object.values(positonedData.TOT_OPER_REV.child).forEach(function (child) {
                    child.bar = __assign(__assign({}, child.bar), { y: child.bar.y + offsetY_1 });
                });
            }
            // 2. 绘制营业收入
            if (positonedData.TOT_OPER_REV) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = currentLane * laneWidth;
                currentLane += 1;
                currentY = 0;
                // 如果当前是第一列，则宽度为营业收入的宽度，否则为laneWidth
                var thisLaneWidth = currentLane === 1
                    ? (((_c = positonedData.TOT_OPER_REV.size) === null || _c === void 0 ? void 0 : _c.w) || 0) +
                        sankeyStyle.padding +
                        sankeyStyle.barWidth
                    : laneWidth;
                currentY += drawBlock({
                    ctx: offCtx,
                    child: positonedData.TOT_OPER_REV,
                    color: color,
                    align: currentLane === 1 ? "left" : "top",
                    x: currentX,
                    y: currentY,
                    x1: currentX + thisLaneWidth,
                    barRatio: barRatio,
                });
                // 居中添加到大画板上，并调整节点位置
                var offsetY = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY);
                if ((_d = positonedData.TOT_OPER_REV) === null || _d === void 0 ? void 0 : _d.bar) {
                    positonedData.TOT_OPER_REV.bar = __assign(__assign({}, positonedData.TOT_OPER_REV.bar), { y: positonedData.TOT_OPER_REV.bar.y + offsetY });
                }
                // 链接1，2
                Object.values(positonedData.TOT_OPER_REV.child || {}).forEach(function (child) {
                    if (child.value && child.bar && positonedData.TOT_OPER_REV.bar) {
                        drawLink(ctx, child.bar, positonedData.TOT_OPER_REV.bar, child.bar.h, child.value * (child.negative ? -1 : 1) >= 0
                            ? color.red
                            : color.green);
                    }
                });
            }
            // 3. 绘制营业利润与支出
            if (positonedData.OTHER_OPER_PROFIT ||
                positonedData.OPER_EXP ||
                positonedData.OPER_PROFIT) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = laneWidth * currentLane;
                currentLane += 1;
                currentY = 0;
                [
                    positonedData.NON_OPER_PROFIT,
                    positonedData.OPER_PROFIT,
                    positonedData.OPER_EXP,
                ].forEach(function (section, index, array) {
                    if (section) {
                        currentY += drawBlock({
                            ctx: offCtx,
                            child: section,
                            color: color,
                            align: index === array.length - 1 && index != 0 ? "bottom" : "top",
                            x: currentX,
                            y: currentY,
                            x1: currentX + laneWidth,
                            barRatio: barRatio,
                        });
                        currentY += sankeyStyle.gap + sankeyStyle.sectionGap;
                    }
                });
                currentY -= sankeyStyle.sectionGap + sankeyStyle.gap;
                // 居中添加到大画板上，并调整节点位置
                var offsetY_2 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_2);
                [
                    positonedData.NON_OPER_PROFIT,
                    positonedData.OPER_EXP,
                    positonedData.OPER_PROFIT,
                ].forEach(function (section) {
                    if (section) {
                        section.bar = __assign(__assign({}, section.bar), { y: section.bar.y + offsetY_2 });
                    }
                });
                // 链接2，3
                [positonedData.OPER_PROFIT, positonedData.OPER_EXP].forEach(function (section) {
                    var _a;
                    if ((section === null || section === void 0 ? void 0 : section.value) && (section === null || section === void 0 ? void 0 : section.bar) && ((_a = positonedData.TOT_OPER_REV) === null || _a === void 0 ? void 0 : _a.bar)) {
                        drawLink(ctx, positonedData.TOT_OPER_REV.bar, section.bar, section.bar.h, section.value * (section.negative ? -1 : 1) >= 0
                            ? color.red
                            : color.green);
                    }
                });
            }
            // 5. 绘制净利润，费用
            if (((_e = positonedData.OPER_PROFIT) === null || _e === void 0 ? void 0 : _e.child) || ((_f = positonedData.OPER_EXP) === null || _f === void 0 ? void 0 : _f.child)) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = currentLane * laneWidth;
                currentLane += 1;
                currentY = 0;
                var thisLaneWidth_2 = Math.ceil(maxWidth([
                    (_g = positonedData.OPER_PROFIT) === null || _g === void 0 ? void 0 : _g.child,
                    (_h = positonedData.OPER_EXP) === null || _h === void 0 ? void 0 : _h.child,
                ])) +
                    sankeyStyle.padding +
                    sankeyStyle.barWidth;
                currentX += laneWidth - thisLaneWidth_2;
                [
                    (_j = positonedData.OPER_PROFIT) === null || _j === void 0 ? void 0 : _j.child,
                    (_k = positonedData.OPER_EXP) === null || _k === void 0 ? void 0 : _k.child,
                ].forEach(function (section) {
                    if (section) {
                        Object.values(section).forEach(function (child, index, array) {
                            currentY += drawBlock({
                                ctx: offCtx,
                                child: child,
                                color: color,
                                align: "right",
                                x: currentX,
                                y: currentY,
                                x1: currentX + thisLaneWidth_2,
                                barRatio: barRatio,
                            });
                            currentY += sankeyStyle.gap;
                        });
                        currentY += sankeyStyle.sectionGap;
                    }
                });
                currentY -= sankeyStyle.gap + sankeyStyle.sectionGap;
                // 居中添加到大画板上，并调整节点位置
                var offsetY_3 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_3);
                [
                    (_l = positonedData.OPER_PROFIT) === null || _l === void 0 ? void 0 : _l.child,
                    (_m = positonedData.OPER_EXP) === null || _m === void 0 ? void 0 : _m.child,
                ].forEach(function (section) {
                    if (section) {
                        Object.values(section).forEach(function (child) {
                            child.bar = __assign(__assign({}, child.bar), { y: child.bar.y + offsetY_3 });
                        });
                    }
                });
                // 链接4，5
                if (((_o = positonedData.NON_OPER_PROFIT) === null || _o === void 0 ? void 0 : _o.bar) && ((_p = positonedData.NON_OPER_PROFIT) === null || _p === void 0 ? void 0 : _p.value) && ((_s = (_r = (_q = positonedData.OPER_PROFIT) === null || _q === void 0 ? void 0 : _q.child) === null || _r === void 0 ? void 0 : _r.NET_PROFIT_INCL_MIN_INT_INC) === null || _s === void 0 ? void 0 : _s.bar)) {
                    drawLink(ctx, positonedData.NON_OPER_PROFIT.bar, positonedData.OPER_PROFIT.child.NET_PROFIT_INCL_MIN_INT_INC.bar, positonedData.NON_OPER_PROFIT.bar.h, positonedData.NON_OPER_PROFIT.value *
                        (positonedData.NON_OPER_PROFIT.negative ? -1 : 1) >=
                        0
                        ? color.red
                        : color.green);
                }
                if ((_t = positonedData.OPER_PROFIT) === null || _t === void 0 ? void 0 : _t.child) {
                    Object.values(positonedData.OPER_PROFIT.child).forEach(function (section) {
                        var _a;
                        if ((section === null || section === void 0 ? void 0 : section.value) && (section === null || section === void 0 ? void 0 : section.bar) && ((_a = positonedData.OPER_PROFIT) === null || _a === void 0 ? void 0 : _a.bar)) {
                            drawLink(ctx, positonedData.OPER_PROFIT.bar, section.bar, section.bar.h, section.value * (section.negative ? -1 : 1) >= 0
                                ? color.red
                                : color.green);
                        }
                    });
                }
                if ((_u = positonedData.OPER_EXP) === null || _u === void 0 ? void 0 : _u.child) {
                    Object.values(positonedData.OPER_EXP.child).forEach(function (section) {
                        var _a;
                        if ((section === null || section === void 0 ? void 0 : section.value) && (section === null || section === void 0 ? void 0 : section.bar) && ((_a = positonedData.OPER_EXP) === null || _a === void 0 ? void 0 : _a.bar)) {
                            drawLink(ctx, positonedData.OPER_EXP.bar, section.bar, section.bar.h, section.value * (section.negative ? -1 : 1) >= 0
                                ? color.red
                                : color.green);
                        }
                    });
                }
            }
            // 7. 输出
            // 裁掉上下空白
            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var firstY = canvas.height;
            var lastY = 0;
            // 扫描画布找到实际内容的上下边界
            for (var y = 0; y < canvas.height; y++) {
                for (var x = 0; x < canvas.width; x++) {
                    var index = (y * canvas.width + x) * 4 + 3; // alpha channel
                    if (imageData.data[index] > 0) {
                        firstY = Math.min(firstY, y);
                        lastY = Math.max(lastY, y);
                    }
                }
            }
            // 如果找到了内容
            if (lastY > firstY) {
                var contentHeight = lastY - firstY;
                // 创建新画布并复制内容
                var newCanvas = document.createElement("canvas");
                newCanvas.width = canvas.width;
                newCanvas.height = contentHeight;
                var newCtx = newCanvas.getContext("2d");
                if (newCtx) {
                    newCtx.drawImage(canvas, 0, firstY, canvas.width, contentHeight, // 源
                    0, 0, canvas.width, contentHeight);
                    // 清空原画布并调整大小
                    canvas.height = contentHeight;
                    ctx.drawImage(newCanvas, 0, 0);
                }
            }
        });
    }, [data, color]);
    return React.createElement("canvas", { ref: canvasRef, className: "h-full w-full" });
};
export default IncomeSankeyF;
