var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from "react";
import sankeyStyle from "../style";
import { drawBlock, drawLink, filterChild, maxWidth, positionData, } from "../sankey";
import useChartColors from "../useChartColors";
var BalanceSankeyF = function (_a) {
    var data = _a.data;
    var canvasRef = useRef(null);
    var color = useChartColors();
    var offCanvas = document.createElement("canvas");
    useEffect(function () {
        if (!data)
            return;
        var lanes = 4;
        var laneWidth = sankeyStyle.chartWidth / lanes;
        // 初始化canvas
        var canvas = canvasRef.current;
        if (!canvas)
            return;
        var ctx = canvas.getContext("2d");
        if (!ctx)
            return;
        var offCtx = offCanvas.getContext("2d");
        if (!offCtx)
            return;
        // Set canvas size
        document.fonts.ready.then(function () {
            var _a, _b;
            canvas.width = sankeyStyle.chartWidth;
            canvas.height = 3000;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            offCanvas.width = canvas.width;
            offCanvas.height = canvas.height;
            offCtx.clearRect(0, 0, canvas.width, canvas.height);
            // 处理数据
            var _c = positionData(data), positonedData = _c.data, barRatio = _c.barRatio;
            var currentX = 0;
            var currentY = 0;
            // 逐列绘制，然后再拼在一起
            // 1. 绘制流动/非流动资产明细
            if ((_a = positonedData.TOT_ASSETS) === null || _a === void 0 ? void 0 : _a.child) {
                // 为了节约面积，找到边列的最大宽度，然后靠边对齐
                filterChild(positonedData.TOT_ASSETS.child, 4, positonedData.TOT_ASSETS.negative);
                var thisLaneWidth_1 = Math.ceil(maxWidth([positonedData.TOT_ASSETS.child])) +
                    sankeyStyle.padding +
                    sankeyStyle.barWidth;
                // 依次绘制节点
                Object.values(positonedData.TOT_ASSETS.child).forEach(function (child, index, array) {
                    currentY += drawBlock({
                        ctx: offCtx,
                        child: child,
                        color: color,
                        align: "left",
                        x: currentX,
                        y: currentY,
                        x1: currentX + thisLaneWidth_1,
                        barRatio: barRatio,
                    });
                    currentY += index === array.length - 1 ? 0 : sankeyStyle.gap;
                });
                // 居中添加到大画板上，并调整节点位置
                var offsetY_1 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_1);
                Object.values(positonedData.TOT_ASSETS.child).forEach(function (child) {
                    child.bar = __assign(__assign({}, child.bar), { y: child.bar.y + offsetY_1 });
                });
            }
            // 2. 绘制资产总额
            if (positonedData.TOT_ASSETS) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = laneWidth;
                currentY = 0;
                currentY += drawBlock({
                    ctx: offCtx,
                    child: positonedData.TOT_ASSETS,
                    color: color,
                    align: "top",
                    x: currentX,
                    y: currentY,
                    x1: currentX + laneWidth,
                    barRatio: barRatio,
                });
                // 居中添加到大画板上，并调整节点位置
                var offsetY = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY);
                positonedData.TOT_ASSETS.bar = __assign(__assign({}, positonedData.TOT_ASSETS.bar), { y: positonedData.TOT_ASSETS.bar.y + offsetY });
                // 链接2，3
                Object.values(positonedData.TOT_ASSETS.child || {}).forEach(function (child) {
                    if (child.value && child.bar && positonedData.TOT_ASSETS.bar) {
                        drawLink(ctx, child.bar, positonedData.TOT_ASSETS.bar, child.bar.h, child.value * (child.negative ? -1 : 1) >= 0
                            ? color.red
                            : color.green);
                    }
                });
            }
            // 3. 绘制股东权益和负债总额
            if (positonedData.TOT_SHRHLDR_EQY_INCL_MIN_INT ||
                positonedData.TOT_LIAB) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = laneWidth * 2;
                currentY = 0;
                [
                    positonedData.TOT_SHRHLDR_EQY_INCL_MIN_INT,
                    positonedData.TOT_LIAB,
                ].forEach(function (section, index, array) {
                    if (section) {
                        currentY += drawBlock({
                            ctx: offCtx,
                            child: section,
                            color: color,
                            align: index === array.length - 1 && index != 0 ? "bottom" : "top",
                            x: currentX,
                            y: currentY,
                            x1: currentX + laneWidth,
                            barRatio: barRatio,
                        });
                        currentY +=
                            index === array.length - 1
                                ? 0
                                : sankeyStyle.gap + sankeyStyle.sectionGap;
                    }
                });
                // 居中添加到大画板上，并调整节点位置
                var offsetY_2 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_2);
                [
                    positonedData.TOT_SHRHLDR_EQY_INCL_MIN_INT,
                    positonedData.TOT_LIAB,
                ].forEach(function (section) {
                    if (section) {
                        section.bar = __assign(__assign({}, section.bar), { y: section.bar.y + offsetY_2 });
                    }
                });
                // 链接3，4
                [
                    positonedData.TOT_SHRHLDR_EQY_INCL_MIN_INT,
                    positonedData.TOT_LIAB,
                ].forEach(function (section) {
                    var _a;
                    if ((section === null || section === void 0 ? void 0 : section.value) && (section === null || section === void 0 ? void 0 : section.bar) && ((_a = positonedData.TOT_ASSETS) === null || _a === void 0 ? void 0 : _a.bar)) {
                        drawLink(ctx, positonedData.TOT_ASSETS.bar, section.bar, section.bar.h, section.value * (section.negative ? -1 : 1) >= 0
                            ? color.red
                            : color.green);
                    }
                });
            }
            // 4. 绘制流动负债、非流动负债明细
            if ((_b = positonedData.TOT_LIAB) === null || _b === void 0 ? void 0 : _b.child) {
                filterChild(positonedData.TOT_LIAB.child, 4, positonedData.TOT_LIAB.negative);
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = laneWidth * 3;
                currentY = 0;
                // 为了节约面积，找到边列的最大宽度，然后靠边对齐
                var thisLaneWidth_2 = Math.ceil(maxWidth([positonedData.TOT_LIAB.child])) +
                    sankeyStyle.padding +
                    sankeyStyle.barWidth;
                currentX += laneWidth - thisLaneWidth_2;
                // 依次绘制节点
                Object.values(positonedData.TOT_LIAB.child).forEach(function (child, index, array) {
                    currentY += drawBlock({
                        ctx: offCtx,
                        child: child,
                        color: color,
                        align: "right",
                        x: currentX,
                        y: currentY,
                        x1: currentX + thisLaneWidth_2,
                        barRatio: barRatio,
                    });
                    currentY += index === array.length - 1 ? 0 : sankeyStyle.gap;
                });
                // 居中添加到大画板上，并调整节点位置
                var offsetY_3 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_3);
                Object.values(positonedData.TOT_LIAB.child).forEach(function (child) {
                    child.bar = __assign(__assign({}, child.bar), { y: child.bar.y + offsetY_3 });
                });
                // 链接5，6
                Object.values(positonedData.TOT_LIAB.child).forEach(function (child) {
                    if (child.value && child.bar && positonedData.TOT_LIAB.bar) {
                        drawLink(ctx, positonedData.TOT_LIAB.bar, child.bar, child.bar.h, child.value * (child.negative ? -1 : 1) >= 0
                            ? color.red
                            : color.green);
                    }
                });
            }
            // 7. 输出
            // 裁掉上下空白
            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var firstY = canvas.height;
            var lastY = 0;
            // 扫描画布找到实际内容的上下边界
            for (var y = 0; y < canvas.height; y++) {
                for (var x = 0; x < canvas.width; x++) {
                    var index = (y * canvas.width + x) * 4 + 3; // alpha channel
                    if (imageData.data[index] > 0) {
                        firstY = Math.min(firstY, y);
                        lastY = Math.max(lastY, y);
                    }
                }
            }
            // 如果找到了内容
            if (lastY > firstY) {
                var contentHeight = lastY - firstY;
                // 创建新画布并复制内容
                var newCanvas = document.createElement("canvas");
                newCanvas.width = canvas.width;
                newCanvas.height = contentHeight;
                var newCtx = newCanvas.getContext("2d");
                if (newCtx) {
                    newCtx.drawImage(canvas, 0, firstY, canvas.width, contentHeight, // 源
                    0, 0, canvas.width, contentHeight);
                    // 清空原画布并调整大小
                    canvas.height = contentHeight;
                    ctx.drawImage(newCanvas, 0, 0);
                }
            }
        });
    }, [data, color]);
    return React.createElement("canvas", { ref: canvasRef, className: "h-full w-full" });
};
export default BalanceSankeyF;
