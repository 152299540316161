var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from "react";
import sankeyStyle from "../style";
import { drawBlock, drawLink, filterChild, maxWidth, positionData, } from "../sankey";
import useChartColors from "../useChartColors";
var IncomeSankey = function (_a) {
    var data = _a.data;
    var canvasRef = useRef(null);
    var color = useChartColors();
    var offCanvas = document.createElement("canvas");
    useEffect(function () {
        var _a, _b;
        if (!data)
            return;
        var lanes = ((_a = data.TOT_OPER_REV) === null || _a === void 0 ? void 0 : _a.child) &&
            Object.keys((_b = data.TOT_OPER_REV) === null || _b === void 0 ? void 0 : _b.child).length > 0
            ? 5
            : 4;
        var currentLane = 0;
        var laneWidth = sankeyStyle.chartWidth / lanes;
        // 初始化canvas
        var canvas = canvasRef.current;
        if (!canvas)
            return;
        var ctx = canvas.getContext("2d");
        if (!ctx)
            return;
        var offCtx = offCanvas.getContext("2d");
        if (!offCtx)
            return;
        // Set canvas size
        document.fonts.ready.then(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
            canvas.width = sankeyStyle.chartWidth;
            canvas.height = 3000;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            offCanvas.width = canvas.width;
            offCanvas.height = canvas.height;
            offCtx.clearRect(0, 0, canvas.width, canvas.height);
            // 处理数据
            var _2 = positionData(data), positonedData = _2.data, barRatio = _2.barRatio;
            var currentX = 0;
            var currentY = 0;
            // 逐列绘制，然后再拼在一起
            // 1. 绘制营业收入明细
            if (((_a = positonedData.TOT_OPER_REV) === null || _a === void 0 ? void 0 : _a.child) &&
                Object.keys((_b = positonedData.TOT_OPER_REV) === null || _b === void 0 ? void 0 : _b.child).length > 0) {
                currentLane += 1;
                // 为了节约面积，找到边列的最大宽度，然后靠边对齐
                var thisLaneWidth_1 = Math.ceil(maxWidth([positonedData.TOT_OPER_REV.child])) +
                    sankeyStyle.padding +
                    sankeyStyle.barWidth;
                // 依次绘制节点
                filterChild(positonedData.TOT_OPER_REV.child, 3);
                Object.values(positonedData.TOT_OPER_REV.child).forEach(function (child) {
                    currentY += drawBlock({
                        ctx: offCtx,
                        child: child,
                        color: color,
                        align: "left",
                        x: currentX,
                        y: currentY,
                        x1: currentX + thisLaneWidth_1,
                        barRatio: barRatio,
                    });
                    currentY += sankeyStyle.gap;
                });
                currentY -= sankeyStyle.gap;
                // 居中添加到大画板上，并调整节点位置
                var offsetY_1 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_1);
                Object.values(positonedData.TOT_OPER_REV.child).forEach(function (child) {
                    child.bar = __assign(__assign({}, child.bar), { y: child.bar.y + offsetY_1 });
                });
            }
            // 2. 绘制营业收入
            if (positonedData.TOT_OPER_REV) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = currentLane * laneWidth;
                currentLane += 1;
                currentY = 0;
                // 如果当前是第一列，则宽度为营业收入的宽度，否则为laneWidth
                var thisLaneWidth = currentLane === 1
                    ? (((_c = positonedData.TOT_OPER_REV.size) === null || _c === void 0 ? void 0 : _c.w) || 0) +
                        sankeyStyle.padding +
                        sankeyStyle.barWidth
                    : laneWidth;
                currentY += drawBlock({
                    ctx: offCtx,
                    child: positonedData.TOT_OPER_REV,
                    color: color,
                    align: currentLane === 1 ? "left" : "top",
                    x: currentX,
                    y: currentY,
                    x1: currentX + thisLaneWidth,
                    barRatio: barRatio,
                });
                // 居中添加到大画板上，并调整节点位置
                var offsetY = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY);
                if ((_d = positonedData.TOT_OPER_REV) === null || _d === void 0 ? void 0 : _d.bar) {
                    positonedData.TOT_OPER_REV.bar = __assign(__assign({}, positonedData.TOT_OPER_REV.bar), { y: positonedData.TOT_OPER_REV.bar.y + offsetY });
                }
                // 链接1，2
                Object.values(positonedData.TOT_OPER_REV.child || {}).forEach(function (child) {
                    if (child.value && child.bar && positonedData.TOT_OPER_REV.bar) {
                        drawLink(ctx, child.bar, positonedData.TOT_OPER_REV.bar, child.bar.h, child.value * (child.negative ? -1 : 1) >= 0
                            ? color.red
                            : color.green);
                    }
                });
            }
            // 3. 绘制营业成本与毛利润
            if (positonedData.OTHER_OPER_PROFIT ||
                positonedData.GROSS_PROFIT ||
                positonedData.LESS_OPER_COST) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = laneWidth * currentLane;
                currentLane += 1;
                currentY = 0;
                [
                    positonedData.OTHER_OPER_PROFIT,
                    positonedData.GROSS_PROFIT,
                    positonedData.LESS_OPER_COST,
                ].forEach(function (section, index, array) {
                    if (section) {
                        currentY += drawBlock({
                            ctx: offCtx,
                            child: section,
                            color: color,
                            align: index === array.length - 1 && index != 0 ? "bottom" : "top",
                            x: currentX,
                            y: currentY,
                            x1: currentX + laneWidth,
                            barRatio: barRatio,
                        });
                        currentY += sankeyStyle.gap + sankeyStyle.sectionGap;
                    }
                });
                currentY -= sankeyStyle.sectionGap + sankeyStyle.gap;
                // 居中添加到大画板上，并调整节点位置
                var offsetY_2 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_2);
                [
                    positonedData.OTHER_OPER_PROFIT,
                    positonedData.GROSS_PROFIT,
                    positonedData.LESS_OPER_COST,
                ].forEach(function (section) {
                    if (section) {
                        section.bar = __assign(__assign({}, section.bar), { y: section.bar.y + offsetY_2 });
                    }
                });
                // 链接2，3
                [positonedData.GROSS_PROFIT, positonedData.LESS_OPER_COST].forEach(function (section) {
                    var _a;
                    if ((section === null || section === void 0 ? void 0 : section.value) && (section === null || section === void 0 ? void 0 : section.bar) && ((_a = positonedData.TOT_OPER_REV) === null || _a === void 0 ? void 0 : _a.bar)) {
                        drawLink(ctx, positonedData.TOT_OPER_REV.bar, section.bar, section.bar.h, section.value * (section.negative ? -1 : 1) >= 0
                            ? color.red
                            : color.green);
                    }
                });
            }
            // 4. 绘制营业利润和费用
            if (positonedData.NON_OPER_PROFIT ||
                positonedData.OPER_PROFIT ||
                positonedData.DEDUCT_OPER_COST) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = laneWidth * currentLane;
                currentLane += 1;
                currentY = 0;
                if (positonedData.NON_OPER_PROFIT) {
                    currentY += drawBlock({
                        ctx: offCtx,
                        child: positonedData.NON_OPER_PROFIT,
                        color: color,
                        align: "top",
                        x: currentX,
                        y: currentY,
                        x1: currentX + laneWidth,
                        barRatio: barRatio,
                    });
                    currentY += sankeyStyle.gap + sankeyStyle.sectionGap;
                }
                [positonedData.OPER_PROFIT, positonedData.DEDUCT_OPER_COST].forEach(function (section, index, array) {
                    if (section) {
                        currentY += drawBlock({
                            ctx: offCtx,
                            child: section,
                            color: color,
                            align: index === array.length - 1 && index != 0 ? "bottom" : "top",
                            x: currentX,
                            y: currentY,
                            x1: currentX + laneWidth,
                            barRatio: barRatio,
                        });
                        currentY += sankeyStyle.gap;
                    }
                });
                currentY -= sankeyStyle.gap;
                // 居中添加到大画板上，并调整节点位置
                var offsetY_3 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_3);
                [
                    positonedData.NON_OPER_PROFIT,
                    positonedData.OPER_PROFIT,
                    positonedData.DEDUCT_OPER_COST,
                ].forEach(function (section) {
                    if (section) {
                        section.bar = __assign(__assign({}, section.bar), { y: section.bar.y + offsetY_3 });
                    }
                });
                // 链接3，4
                if (((_e = positonedData.OTHER_OPER_PROFIT) === null || _e === void 0 ? void 0 : _e.bar) && ((_f = positonedData.OPER_PROFIT) === null || _f === void 0 ? void 0 : _f.bar) && ((_g = positonedData.OTHER_OPER_PROFIT) === null || _g === void 0 ? void 0 : _g.value)) {
                    drawLink(ctx, positonedData.OTHER_OPER_PROFIT.bar, positonedData.OPER_PROFIT.bar, positonedData.OTHER_OPER_PROFIT.bar.h, positonedData.OTHER_OPER_PROFIT.value *
                        (positonedData.OTHER_OPER_PROFIT.negative ? -1 : 1) >=
                        0
                        ? color.red
                        : color.green);
                }
                if (((_h = positonedData.OPER_PROFIT) === null || _h === void 0 ? void 0 : _h.bar) && ((_j = positonedData.GROSS_PROFIT) === null || _j === void 0 ? void 0 : _j.bar) &&
                    positonedData.OPER_PROFIT.value) {
                    var value = positonedData.OTHER_OPER_PROFIT.value
                        ? positonedData.OPER_PROFIT.value -
                            positonedData.OTHER_OPER_PROFIT.value
                        : positonedData.OPER_PROFIT.value;
                    var h = value * barRatio;
                    drawLink(ctx, positonedData.GROSS_PROFIT.bar, positonedData.OPER_PROFIT.bar, h, value * (positonedData.OPER_PROFIT.negative ? -1 : 1) >= 0
                        ? color.red
                        : color.green);
                }
                if (((_k = positonedData.DEDUCT_OPER_COST) === null || _k === void 0 ? void 0 : _k.bar) && ((_l = positonedData.GROSS_PROFIT) === null || _l === void 0 ? void 0 : _l.bar) &&
                    positonedData.DEDUCT_OPER_COST.value) {
                    drawLink(ctx, positonedData.GROSS_PROFIT.bar, positonedData.DEDUCT_OPER_COST.bar, positonedData.DEDUCT_OPER_COST.bar.h, positonedData.DEDUCT_OPER_COST.value *
                        (positonedData.DEDUCT_OPER_COST.negative ? -1 : 1) >=
                        0
                        ? color.red
                        : color.green);
                }
            }
            // 5. 绘制净利润，费用
            if (((_m = positonedData.OPER_PROFIT) === null || _m === void 0 ? void 0 : _m.child) || ((_o = positonedData.DEDUCT_OPER_COST) === null || _o === void 0 ? void 0 : _o.child)) {
                offCtx.clearRect(0, 0, offCanvas.width, offCanvas.height);
                currentX = currentLane * laneWidth;
                currentLane += 1;
                currentY = 0;
                var thisLaneWidth_2 = Math.ceil(maxWidth([
                    (_p = positonedData.OPER_PROFIT) === null || _p === void 0 ? void 0 : _p.child,
                    (_q = positonedData.DEDUCT_OPER_COST) === null || _q === void 0 ? void 0 : _q.child,
                ])) +
                    sankeyStyle.padding +
                    sankeyStyle.barWidth;
                currentX += laneWidth - thisLaneWidth_2;
                [
                    (_r = positonedData.OPER_PROFIT) === null || _r === void 0 ? void 0 : _r.child,
                    (_s = positonedData.DEDUCT_OPER_COST) === null || _s === void 0 ? void 0 : _s.child,
                ].forEach(function (section) {
                    if (section) {
                        Object.values(section).forEach(function (child, index, array) {
                            currentY += drawBlock({
                                ctx: offCtx,
                                child: child,
                                color: color,
                                align: "right",
                                x: currentX,
                                y: currentY,
                                x1: currentX + thisLaneWidth_2,
                                barRatio: barRatio,
                            });
                            currentY += sankeyStyle.gap;
                        });
                        currentY += sankeyStyle.sectionGap;
                    }
                });
                currentY -= sankeyStyle.gap + sankeyStyle.sectionGap;
                // 居中添加到大画板上，并调整节点位置
                var offsetY_4 = (canvas.height - currentY) / 2;
                ctx.drawImage(offCanvas, 0, offsetY_4);
                [
                    (_t = positonedData.OPER_PROFIT) === null || _t === void 0 ? void 0 : _t.child,
                    (_u = positonedData.DEDUCT_OPER_COST) === null || _u === void 0 ? void 0 : _u.child,
                ].forEach(function (section) {
                    if (section) {
                        Object.values(section).forEach(function (child) {
                            child.bar = __assign(__assign({}, child.bar), { y: child.bar.y + offsetY_4 });
                        });
                    }
                });
                // 链接4，5
                if (((_v = positonedData.NON_OPER_PROFIT) === null || _v === void 0 ? void 0 : _v.bar) && ((_w = positonedData.NON_OPER_PROFIT) === null || _w === void 0 ? void 0 : _w.value) && ((_z = (_y = (_x = positonedData.OPER_PROFIT) === null || _x === void 0 ? void 0 : _x.child) === null || _y === void 0 ? void 0 : _y.NET_PROFIT_INCL_MIN_INT_INC) === null || _z === void 0 ? void 0 : _z.bar)) {
                    drawLink(ctx, positonedData.NON_OPER_PROFIT.bar, positonedData.OPER_PROFIT.child.NET_PROFIT_INCL_MIN_INT_INC.bar, positonedData.NON_OPER_PROFIT.bar.h, positonedData.NON_OPER_PROFIT.value *
                        (positonedData.NON_OPER_PROFIT.negative ? -1 : 1) >=
                        0
                        ? color.red
                        : color.green);
                }
                if ((_0 = positonedData.OPER_PROFIT) === null || _0 === void 0 ? void 0 : _0.child) {
                    Object.values(positonedData.OPER_PROFIT.child).forEach(function (section) {
                        var _a;
                        if ((section === null || section === void 0 ? void 0 : section.value) && (section === null || section === void 0 ? void 0 : section.bar) && ((_a = positonedData.OPER_PROFIT) === null || _a === void 0 ? void 0 : _a.bar)) {
                            drawLink(ctx, positonedData.OPER_PROFIT.bar, section.bar, section.bar.h, section.value * (section.negative ? -1 : 1) >= 0
                                ? color.red
                                : color.green);
                        }
                    });
                }
                if ((_1 = positonedData.DEDUCT_OPER_COST) === null || _1 === void 0 ? void 0 : _1.child) {
                    Object.values(positonedData.DEDUCT_OPER_COST.child).forEach(function (section) {
                        var _a;
                        if ((section === null || section === void 0 ? void 0 : section.value) && (section === null || section === void 0 ? void 0 : section.bar) && ((_a = positonedData.DEDUCT_OPER_COST) === null || _a === void 0 ? void 0 : _a.bar)) {
                            drawLink(ctx, positonedData.DEDUCT_OPER_COST.bar, section.bar, section.bar.h, section.value * (section.negative ? -1 : 1) >= 0
                                ? color.red
                                : color.green);
                        }
                    });
                }
            }
            // 7. 输出
            // 裁掉上下空白
            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var firstY = canvas.height;
            var lastY = 0;
            // 扫描画布找到实际内容的上下边界
            for (var y = 0; y < canvas.height; y++) {
                for (var x = 0; x < canvas.width; x++) {
                    var index = (y * canvas.width + x) * 4 + 3; // alpha channel
                    if (imageData.data[index] > 0) {
                        firstY = Math.min(firstY, y);
                        lastY = Math.max(lastY, y);
                    }
                }
            }
            // 如果找到了内容
            if (lastY > firstY) {
                var contentHeight = lastY - firstY;
                // 创建新画布并复制内容
                var newCanvas = document.createElement("canvas");
                newCanvas.width = canvas.width;
                newCanvas.height = contentHeight;
                var newCtx = newCanvas.getContext("2d");
                if (newCtx) {
                    newCtx.drawImage(canvas, 0, firstY, canvas.width, contentHeight, // 源
                    0, 0, canvas.width, contentHeight);
                    // 清空原画布并调整大小
                    canvas.height = contentHeight;
                    ctx.drawImage(newCanvas, 0, 0);
                }
            }
        });
    }, [data, color]);
    return React.createElement("canvas", { ref: canvasRef, className: "h-full w-full" });
};
export default IncomeSankey;
